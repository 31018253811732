import Environment from "../../system/Environment";
import CartStorage from "../core/CartStorage";
import ListingStorage from "../core/ListingStorage";
import SessionStorage from "../core/SessionStorage";
import HttpService from "../HttpService";

class CartService extends HttpService {

  async initializeCart() {
    const obj = {};
    // if (SessionS.session) {
    //   obj.userId = this.sessionService.session._id;

    // }

    return this.axios.post(
      `${Environment.merchantApi.baseUrl}/v1/listings/${ListingStorage.information._id}/restaurant/cart`, obj
    );
  }

  async addItemToCart(obj) {
    let cart = CartStorage.cart;
    if (!cart) {
      cart = await this.initializeCart();
    }
    return this.axios.put(`${Environment.merchantApi.baseUrl}/v1/listings/${ListingStorage.information._id}/restaurant/cart/${cart.cartId}/items`, obj);
  }

  async updateItemInCart(cartItemId, obj) {
    let cart = CartStorage.cart;
    return this.axios.patch(`${Environment.merchantApi.baseUrl}/v1/listings/${ListingStorage.information._id}/restaurant/cart/${cart.cartId}/items/${cartItemId}`, obj);
  }

  async removeItemInCart(cartItemId) {
    let cart = CartStorage.cart;
    return this.axios.delete(`${Environment.merchantApi.baseUrl}/v1/listings/${ListingStorage.information._id}/restaurant/cart/${cart.cartId}/items/${cartItemId}`);
  }

  async getOrderEstimate(params) {
    return this.axios.get(
      `${Environment.merchantApi.baseUrl}/v1/listings/${ListingStorage.information._id}/restaurant/orders/estimate`, {
      params
    });
  }

  async processCheckout(obj) {
    const userId = SessionStorage.session._id;
    return this.axios.post(
      `${Environment.merchantApi.baseUrl}/v1/listings/${ListingStorage.information._id}/restaurant/orders/users/${userId}/checkout`, obj);
  }

  async getOrder(orderId) {
    const userId = SessionStorage.session._id;
    return this.axios.get(
      `${Environment.merchantApi.baseUrl}/v1/users/${userId}/restaurants/orders/${orderId}`);
  }
}

export default new CartService();
