import { Box, Grid, IconButton, Typography, withStyles } from "@material-ui/core";
import { ChevronLeft, Receipt } from "@material-ui/icons";
import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import UserService from "../services/api/UserService";

class RecentOrdersPage extends Component {
  state = {
    orders: null
  };

  async componentDidMount() {
    const orders = await UserService.getAllUsersOrders();
    console.log(orders);
    this.setState({ orders });
  }

  render() {
    const { classes, history } = this.props;
    const { orders } = this.state;
    return (
      <Grid container direction="column" wrap="nowrap">
        <Grid container justifyContent="flex-start" alignItems="center" wrap="nowrap" spacing={1} className={classes.itemHolder}>
          <Grid item>
            <IconButton onClick={() => history.goBack()}><ChevronLeft /></IconButton>
          </Grid>
          <Grid item>
            <Typography variant="h3">Recent Orders</Typography>
          </Grid>
        </Grid>
        <Grid item>
          <Grid container direction="column">
            {orders?.data?.map(order =>
              <Grid item key={order._id}>
                <Box className={classes.orderHolder}>
                  <Box className={classes.orderInfoHolder}>
                    <Typography style={{ flex: 1 }}>{order.listing?.name}</Typography>
                    <Typography>{Intl.NumberFormat('en-US', { style: "currency", currency: "USD", minimumFractionDigits: 2, }).format((order.amount) / 100)}</Typography>
                  </Box>
                  <Typography>{order.createdAtReadable}</Typography>

                  <IconButton onClick={() => {
                    window.open(order.receipt);
                  }}><Receipt /></IconButton>

                </Box>
              </Grid>
            )}
          </Grid>

        </Grid>
      </Grid >

    );
  }
}

export default withStyles(theme => ({
  itemHolder: {
    padding: "16px 16px 8px"
  },
  orderHolder: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row',
    padding: "16px 24px",
    borderBottomColor: theme.palette.divider,
    borderBottomWidth: 1,
    borderBottomStyle: "solid"
  },
  orderInfoHolder: {
    display: 'flex',
    flex: 1,
    justifyContent: 'center',
    flexDirection: 'column',
  }
}))(withRouter(RecentOrdersPage));
