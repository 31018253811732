import { FormControlLabel, Grid, makeStyles, Radio, RadioGroup, Typography } from '@material-ui/core';
import React from 'react';

const useStyles = makeStyles(theme => (
  {
    holder: {
      marginTop: 16,
      marginBottom: 16,
      marginLeft: -8,
      marginRight: -8,
      paddingLeft: 24,
      paddingRight: 24,
      "& .MuiFormControlLabel-label": {
        width: "100%"
      },
    },
    price: {
      color: "#8f95a3",
      fontSize: "14px",
    }
  }
))

const SingleModifier = ({ modifier, onChange, value }) => {
  const classes = useStyles();
  if (!value) {
    value = modifier.options[0]._id
  }

  return (
    <Grid container className={classes.holder} direction="column" display="flex" spacing={1}>
      <Grid item>
        <Typography variant="h6">{modifier.name}</Typography>
        <Typography variant="subtitle2">Required</Typography>
      </Grid>
      <Grid item>
        <Typography variant="caption">{modifier.description}</Typography>
      </Grid>
      <Grid item>
        <RadioGroup aria-label="gender" name="gender1" value={value} onChange={onChange}>
          {modifier.options.map(option => <FormControlLabel key={option._id} value={option._id} control={<Radio />} label={(
            <Grid container justifyContent="space-between">
              <Grid item style={{ width: "100%", flex: 1 }}>
                <Typography>
                  {option.name}
                </Typography>
              </Grid>
              {option.price > 0 && <Grid item>
                <Typography className={classes.price} >
                  +{Intl.NumberFormat('en-US', { style: "currency", currency: "USD", minimumFractionDigits: 2, }).format(option.price / 100)}
                </Typography>
              </Grid>}
            </Grid>
          )} />)}
        </RadioGroup>
      </Grid>
    </Grid>
  );
}

export default SingleModifier;