import { Box, IconButton, Menu, MenuItem, Typography, withStyles } from '@material-ui/core';
import { Person } from '@material-ui/icons';
import React, { Component } from 'react';
import { withRouter } from "react-router";
import SessionStorage from '../services/core/SessionStorage';

class UserOptions extends Component {
  state = {
    anchorEl: null,
    session: SessionStorage.session
  }

  componentDidMount() {
    this.sessionSubscription = SessionStorage.sessionSubject.subscribe(session => {
      this.setState({ session });
    });
  }

  componentWillUnmount() {
    if (this.sessionSubscription) this.sessionSubscription.unsubscribe();
  }

  handleClick = (event) => {
    this.setState({ anchorEl: event.currentTarget });
  }

  handleClose = () => {
    this.setState({ anchorEl: null });
  }

  signOut = () => {
    SessionStorage.session = undefined;
    this.handleClose();
  }

  recentOrders = () => {
    const { history } = this.props;
    if (SessionStorage.session && SessionStorage.token) {
      history.push('/recent-orders');
    } else {
      history.push(`/auth/login?redirect=recent-orders`);
    }
  }

  render() {

    const { anchorEl, session } = this.state;
    const { handleClose, handleClick, signOut, recentOrders } = this;
    const { classes } = this.props;

    return (
      <>
        <IconButton aria-controls="user-options" aria-haspopup="true" onClick={handleClick} color="primary">
          <Person />
        </IconButton>
        <Menu
          id="user-options"
          anchorEl={anchorEl}
          getContentAnchorEl={null}
          keepMounted
          TransitionComponent={this.Transition}
          anchorOrigin={{
            horizontal: "right",
            vertical: "bottom",
          }}
          variant="menu"
          open={Boolean(anchorEl)}
          onClose={handleClose}
        >
          <Box className={classes.mainContainer}>
            <Box className={classes.title}>
              <Typography variant="h4">Options</Typography>
            </Box>
            <Box>
              <MenuItem onClick={recentOrders}>Recent Orders</MenuItem>
              {session && <MenuItem onClick={signOut}>Signout</MenuItem>}
            </Box>
          </Box>
        </Menu>
      </>
    );
  }
}

export default withStyles(theme => ({
  mainContainer: {
    width: "240px !important",
    maxWidth: "100%",
  },
  title: {
    padding: 16,
  }
}))(withRouter(UserOptions));