import { IconButton, Grid, Typography, makeStyles, Button, CircularProgress } from '@material-ui/core';
import { Check, Delete } from '@material-ui/icons';
import React from 'react';

const useStyles = makeStyles(theme => ({
  brand: {
    height: 20,
    objectFit: 'contain',
    marginRight: 16,
  },
  paymentName: {
    fontSize: 14,
    textAlign: "start",
    flex: 1,
    textTransform: 'capitalize'
  },
  paymentExpiration: {
    fontSize: 12,
    color: theme.palette.text,
    textAlign: "start",
    flex: 1,
    textTransform: 'capitalize'
  },
  paymentOption: {
    height: 56,
    width: "100%",
    paddingLeft: 16,
    paddingRight: 16,
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center"
  },
}))

const CreditCardItem = ({ editToggled, card, selected, onCardSelected, onRemove }) => {
  const [loading, setLoading] = React.useState(false);
  const classes = useStyles();

  const onRemoveInternal = (cardId) => {
    setLoading(true);
    onRemove(cardId)
  }

  return (
    <Grid item key={card.id}>
      <Button className={classes.paymentOption} disableRipple={editToggled} onClick={() => {
        if (editToggled) {
          return;
        }
        onCardSelected(card.id);
      }}>
        {card.brand && <img alt={card.brand} className={classes.brand} src={require(`../../assets/images/cards/${card.brand}.png`).default} />}
        <Grid container direction="column">
          <Grid item>

            <Typography className={classes.paymentName}>{card.brand} - {card.last4}</Typography>
          </Grid>
          <Grid item>
            <Typography className={classes.paymentExpiration}>Exp: {card.expMonth}/{card.expYear}</Typography>
          </Grid>
        </Grid>
        {loading && <CircularProgress size={24} />}
        {editToggled && !loading && <IconButton onClick={() => {
          onRemoveInternal(card?.id);
        }}><Delete /></IconButton>}
        {!editToggled && selected?.id === card?.id && <Check />}
      </Button>
    </Grid>
  );
}

export default CreditCardItem;