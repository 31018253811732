import Environment from "../../system/Environment";
import ListingStorage from "../core/ListingStorage";
import HttpService from "../HttpService";

class ListingService extends HttpService {
  async getListingInformation(listingId) {
    return this.axios.get(
      `${Environment.api.baseUrl}/v1/listings/${listingId}/basic`
    );
  }

  async getRestaurantMenu() {
    return this.axios.get(
      `${Environment.merchantApi.baseUrl}/v1/listings/${ListingStorage.information._id}/restaurant/browse`
    );
  }

  async getRestaurantItem(itemId) {
    return this.axios.get(
      `${Environment.merchantApi.baseUrl}/v1/listings/${ListingStorage.information._id}/restaurant/items/${itemId}`
    )
  }

  async getRestaurantStatus() {
    return this.axios.get(
      `${Environment.merchantApi.baseUrl}/v1/listings/${ListingStorage.information._id}/restaurant/status`
    )
  }
}

export default new ListingService();
