import { Card, CardActionArea, Grid, makeStyles, Typography } from "@material-ui/core";
import React from "react";

const useStyles = makeStyles((theme) => ({
  title: {
    lineHeight: "22px",
    fontWeight: 700,
    fontSize: 16,
    textTransform: "none",
    padding: 0,
    display: "block",
    fontVariantLigatures: "no-common-ligatures",
    maxWidth: "100%",
    overflow: "hidden",
    textOverflow: "ellipsis",
    height: 22,
  },
  description: {
    fontSize: 14,
    fontWeight: 500,
    letterSpacing: 0,
    textTransform: "none",
    color: "rgb(118, 118, 118)",
    margin: 0,
    marginTop: 4,
    padding: 0,
    fontVariantLigatures: "no-common-ligatures",
    maxWidth: "100%",
    overflow: "hidden",
    textOverflow: "ellipsis",
    maxHeight: 48,
    height: 48,
    minHeight: 48,
    whitespace: "normal",
    display: "-webkit-box",
    WebkitBoxOrient: 'vertical',
    WebkitLineClamp: 2,
  },
  price: {
    height: 20,
    fontSize: 14,
    fontWeight: 500,
    letterSpacing: 0,
    textTransform: "none",
    color: "rgb(73, 73, 73)",
    margin: 0,
    padding: 0,
    display: "block",
    marginTop: 4,
    fontVariantLigatures: "no-common-ligatures",
  },
  holder: {
    height: 142,
    maxHeight: 142,
    borderWidth: 1,
    borderColor: "rgb(231, 231, 231)",
    borderStyle: "solid",
  },
  infoHolder: {
    flex: 1,
    maxHeight: 142,
    height: 142,
    justifyContent: 'center',
    flexDirection: "column",
    display: "flex",
    padding: 16
  },
  image: {
    height: 142,
    width: 142,
    objectFit: "cover"
  }
}));

const CategoryItem = ({ item, onClick }) => {
  const classes = useStyles();

  return (
    <Card className={classes.holder} elevation={0} onClick={onClick}>
      <CardActionArea>
        <Grid container direction="row">
          <Grid item className={classes.infoHolder} display={"flex"}>
            <Typography className={classes.title}>{item.name}</Typography>
            <Typography className={classes.description}>{item.description}</Typography>
            <Typography className={classes.price}>${Intl.NumberFormat('en-US').format(item.price.value / 100)}</Typography>

          </Grid>
          {item.cover && <Grid item>
            <img src={item.cover} className={classes.image} alt={"Food Icon"} />
          </Grid>}
        </Grid>
      </CardActionArea>
    </Card>
  );
};

export default CategoryItem;
