import { Toolbar } from "@material-ui/core";
import { withStyles } from "@material-ui/styles";
import React, { Component } from "react";
import CartMenu from "./CartMenu";
import CategoryMenu from "./CategoryMenu";
import UserOptions from "./UserOptions";

class TopBar extends Component {

  render() {
    const { classes, category, categories, onCategorySelected, onItemClicked, onItemRemoved, onCheckoutClicked } = this.props;

    return (
      <Toolbar className={classes.header}>
        <CategoryMenu category={category} categories={categories} onSelected={onCategorySelected} />
        <span className={classes.spacing}></span>
        <CartMenu onItemClicked={onItemClicked} onItemRemoved={onItemRemoved} onCheckoutClicked={onCheckoutClicked} />
        <UserOptions />
      </Toolbar>
    );
  }
}

export default withStyles(theme => ({
  header: {
    borderBottomStyle: "solid",
    borderBottomColor: "#e3e3e3",
    position: "sticky",
    top: 0,
    backgroundColor: theme.palette.white.main,
    zIndex: 10,
    borderBottomWidth: 1
  },
  spacing: {
    flex: 1,
  }
}))(TopBar);
