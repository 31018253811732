import { Box, Button, ButtonBase, Divider, Paper, TextField, Typography, withStyles } from '@material-ui/core';
import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { toast } from 'react-toastify';
import UserService from '../../services/api/UserService';
import SessionStorage from '../../services/core/SessionStorage';

class RegisterPage extends Component {
  state = {
    name: '',
    email: '',
    password: '',
    confirm: '',
    loading: false
  }
  render() {
    const { classes, history, location: { search } } = this.props;
    const { name, email, password, confirm } = this.state;
    return (
      <div className={classes.container}>
        <Paper className={classes.paper}>
          <Box className={classes.inner}>
            <Typography variant="h5" className={classes.title}>
              SIGN UP
            </Typography><Typography className={classes.subtitle}>
              Enter your information
            </Typography>

            <TextField
              id="name"
              label="Name"
              autoComplete="name"
              fullWidth
              className={classes.textField}
              onChange={event => this.setState({ name: event.target.value })}
              value={name}
            />
            <TextField
              id="email"
              label="Email"
              autoComplete="email"
              fullWidth
              className={classes.textField}
              onChange={event => this.setState({ email: event.target.value })}
              value={email}
            />
            <TextField
              id="password"
              label="Password"
              type="password"
              autoComplete="new-password"
              fullWidth
              className={classes.textField}
              error={!/^(?=.*[A-Za-z])(?=.*\d).{8,}$/.test(password)}
              onChange={event => this.setState({ password: event.target.value })}
              value={password}
            />
            <TextField
              id="confirm"
              label="Confirm Password"
              type="password"
              autoComplete="new-password"
              fullWidth
              className={classes.textField}
              error={confirm !== password}
              onChange={event => this.setState({ confirm: event.target.value })}
              value={confirm}
            />
            <Box className={classes.agreement}>
              <Typography variant="caption" >
                You agree to our <ButtonBase disableRipple><Typography className={classes.link} variant="caption">Terms of Service</Typography></ButtonBase> and <ButtonBase disableRipple><Typography className={classes.link} variant="caption">Privacy Policy</Typography></ButtonBase>.
              </Typography>
            </Box>
            <Button
              variant="contained"
              fullWidth
              color="primary"
              className={classes.button}
              onClick={async () => {
                await this.setState({ loading: true });
                const { email, password } = this.state;
                const redirect = new URLSearchParams(this.props.location.search).get('redirect');
                try {
                  const user = await UserService.register({ name, email, password, type: 'web' });
                  SessionStorage.session = user;
                  toast.success(`Welcome ${user.name}!`);
                  this.props.history.replace('/' + redirect);
                } catch (e) {
                  console.log(e);
                  toast.error('Unable to register. Please try again later.');
                } finally {
                  await this.setState({ loading: false });
                }
              }}
            >Continue</Button>
          </Box>
          <Divider />
          <Box className={classes.innerBottom}>
            <Typography variant="caption" align="center">
              Have an account?
            </Typography>
            <Button variant="text" fullWidth color="primary" onClick={() => history.replace('/auth/login' + search)}>Log In</Button>
          </Box>
        </Paper>
      </div>
    );
  }
}

export default withStyles(theme => ({
  container: {
    display: "flex",
    justifyContent: "center",
    alignItems: "flex-start",
    backgroundColor: "rgb(246, 246, 246)"
  },
  paper: {
    margin: "48px 32px",
    width: 375,
    [theme.breakpoints.down("xs")]: {
      width: 300,
    }
  },
  inner: {
    padding: 32
  },
  innerBottom: {
    display: 'flex',
    flexDirection: 'column',
    padding: "24px 32px",
  },
  title: {
    marginBottom: 16,
    marginLeft: 16,
    marginRight: 16,
    fontWeight: "bold",
    textAlign: "center"
  },
  subtitle: {
    marginBottom: 16,
    marginLeft: 16,
    marginRight: 16,
    textAlign: "center",
    fontSize: 18,
  },
  agreement: {
    marginBottom: 32,
  },
  textField: {
    marginBottom: 16,
  },
  link: {
    fontWeight: 'bold'
  },
  button: {
    height: 56,
  }

}))(withRouter(RegisterPage));