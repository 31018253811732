import { CircularProgress, Grid, Typography, withStyles } from '@material-ui/core';
import { Check } from '@material-ui/icons';
import React, { Component } from 'react';

class Tracker extends Component {
  state = {}

  renderIcon(item, index, current) {
    const { currentTracking } = this.props;
    if (currentTracking.id === item.id && currentTracking.status === 1) {
      return <CircularProgress size={14} />
    }

    if (current >= index) {
      return <Check fontSize={index === current ? "medium" : "small"} style={{
        color: index === current ? 'unset' : "rgba(0, 0, 0, .5)",
      }} />
    } else {
      return <Check fontSize="small" style={{
        visibility: 'hidden'
      }} />
    }
  }

  render() {
    const { tracking, currentTracking, classes } = this.props;
    const current = tracking?.findIndex(t => currentTracking.id === t.id);
    console.log(current);
    return (
      <Grid container direction="column" className={classes.allTrackers}>
        {tracking?.map((t, index) => (<Grid key={t.id} item className={classes.trackingHolder}>
          {this.renderIcon(t, index, current)}
          <Typography className={classes.trackingInfo}
            style={{
              fontSize: index === current ? 18 : "unset",
              color: index === current ? 'unset' : "rgba(0, 0, 0, .5)",
              fontWeight: index === current ? 'bold' : 'unset'
            }}>{t.text}</Typography>
        </Grid>))}
      </Grid>
    );
  }
}

export default withStyles(theme => ({
  allTrackers: {
    paddingLeft: 16,
    paddingRight: 16,
    marginTop: 8
  },
  trackingHolder: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: "flex-start",
    alignItems: "center",
    marginTop: 8,

  },
  trackingInfo: {
    marginLeft: 16
  }
}))(Tracker);