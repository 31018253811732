import { Grid, makeStyles, Typography } from '@material-ui/core';
import React from 'react';

const useStyles = makeStyles(theme => ({
  itemContainer: {
    padding: 16,
    minHeight: "56px",
    borderBottomStyle: "solid",
    borderBottomWidth: 1,
    borderBottomColor: "rgba(217,219,224,.5)",
    [theme.breakpoints.down("xs")]: {
      paddingLeft: "16px",
      paddingRight: "16px",
    },
  },
  itemQuantity: {
    fontSize: "14px",
    fontWeight: "600",
  },
  itemInfoContainer: {
    padding: "0 20px",
    alignItems: "start",
    justifyContent: "flex-start",
    display: "flex",
    flexDirection: "column",
  },
  itemTitle: {
    lineHeight: "20px",
    fontSize: "16px",
    fontWeight: "500",
    textAlign: "start"
  },
  itemSubTitle: {
    lineHeight: "20px",
    fontSize: "14px",
    textAlign: "start"
  },
  itemNotes: {
    color: "#545454",
    lineHeight: "20px",
    fontSize: "12px",
    textAlign: "start"
  },
  itemPrice: {
    fontSize: "14px",
    fontWeight: "600",
  },
  itemRemove: {
    marginLeft: "8px",
    padding: 0,
  },
}));

const ItemSection = ({ item }) => {
  const classes = useStyles();

  return (
    <Grid container className={classes.itemContainer} direction="row" wrap="nowrap">
      <Grid item>
        <Typography className={classes.itemQuantity}>{item.quantity}</Typography>
      </Grid>
      <Grid container direction="column" className={classes.itemInfoContainer}>
        <Grid item>
          <Typography className={classes.itemTitle}>{item.name}</Typography>

        </Grid>
        <Grid item>
          {item.subtext && <Typography className={classes.itemSubTitle}>{item.subtext}</Typography>}

        </Grid>
        <Grid item>
          {item.notes && <Typography className={classes.itemNotes}>Notes: {item.notes}</Typography>}

        </Grid>

      </Grid>
      <Grid item>
        <Typography className={classes.itemPrice}>{Intl.NumberFormat('en-US', { style: "currency", currency: "USD", minimumFractionDigits: 2, }).format(item.charges.subtotal / 100)}</Typography>
      </Grid>
    </Grid>
  );
}

export default ItemSection;