const Environment = {
  api: {
    baseUrl:
      process.env.NODE_ENV === 'production' ? 'https://api.neighborhoodmuslim.com/api' : 'https://stg-api.neighborhoodmuslim.com/api',
  },
  merchantApi: {
    baseUrl:
      process.env.NODE_ENV === 'production' ? 'https://merchant-api.neighborhoodmuslim.com/api' : 'https://stg-merchant-api.neighborhoodmuslim.com/api',
  },
  stripe: {
    key: process.env.NODE_ENV === 'production' ? 'pk_live_YrewdGCuU3tm9i7hSvUIgmh8004uis8DHn' :
      "pk_test_9IXOXW8f0kKX4HA8b3SdjWju003RM7cvLp",

  },
  firebase: {
    google: {
      clientId: '473789031668-ilb9tvmiop3e67rqemsjlifb2bq6a1pc.apps.googleusercontent.com',
    },
    facebook: {
      appId: '627347194536036'
    }
  }
};

export default Environment;
