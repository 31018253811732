import { Subject } from "rxjs";

class ListingStorage {

  statusSub = new Subject();

  get information() {
    return this._info;
  }

  set information(info) {
    this._info = info;
  }

  get status() {
    return this._status;
  }

  set status(status) {
    this._status = status;
    this.statusSub.next(status);
  }

  get menu() {
    return this._menu;
  }

  set menu(menu) {
    this._menu = menu;
  }
}

export default new ListingStorage();
