import { Button, CircularProgress, Divider, Grid, IconButton, List, ListItem, ListItemIcon, ListItemSecondaryAction, ListItemText, Typography, withStyles } from "@material-ui/core";
import { ChevronLeft, CreditCard, LocalOffer, Schedule } from "@material-ui/icons";
import React, { Component } from "react";
import { withRouter } from "react-router";
import { toast } from "react-toastify";
import CreditCardDialog from "../../components/CheckoutPage/CreditCardDialog";
import ItemSection from "../../components/CheckoutPage/ItemSection";
import PhoneValidatorDialog from "../../components/CheckoutPage/PhoneValidatorDialog";
import PromoCodeDialog from "../../components/CheckoutPage/PromoCodeDialog";
import CartService from "../../services/api/CartService";
import UserService from "../../services/api/UserService";
import CartStorage from "../../services/core/CartStorage";
import ListingStorage from "../../services/core/ListingStorage";
import SessionStorage from "../../services/core/SessionStorage";

class CheckoutPage extends Component {
  state = {
    cart: CartStorage.cart,
    cards: null,
    ccIsOpen: false,
    phoneIsOpen: false,
    promoIsOpen: false,
    phone: null,
    cardSelected: null,
    estimate: null,
    checkoutProcessing: false,
    status: ListingStorage.status,
    loading: false
  }

  async validatePhone() {
    try {
      const pr = await UserService.getPhoneNumber();
      if (pr.phone) {
        this.setState({ phone: pr.phone });
      } else {
        this.setState({ phoneIsOpen: true })
      }
    } catch (e) {
      this.setState({ phoneIsOpen: true })
    }
  }

  async componentDidMount() {
    if (!SessionStorage.session || !SessionStorage.token) {
      this.props.history.push(`/auth/login?redirect=${ListingStorage.information._id}/checkout`);
      return;
    }
    this.setState({ loading: true });
    const cards = await UserService.cards();

    await this.validatePhone();

    const estimate = await CartService.getOrderEstimate({ type: 'pickup', cartId: CartStorage.cart.cartId });
    this.setState({ cards, cardSelected: cards.sources.find(c => c.id === cards.defaultSource), estimate, loading: false });


    this.statusSubscription = ListingStorage.statusSub.subscribe(status => {
      this.setState({ status });
    });
  }

  componentWillUnmount() {
    if (this.statusSubscription) this.statusSubscription.unsubscribe();
  }

  render() {
    const { classes, history } = this.props;
    const { cart, ccIsOpen, cards, cardSelected, estimate, phoneIsOpen, phone, checkoutProcessing, status, promoIsOpen } = this.state;
    const listing = ListingStorage.information;

    return (
      <Grid container className={classes.parentHolder}>
        <Grid container display="flex" direction="column" wrap="nowrap" className={classes.checkoutHolder} item xs={12} md={6}>
          <Grid container justifyContent="flex-start" alignItems="center" wrap="nowrap" spacing={1} className={classes.itemHolder}>
            <Grid item>
              <IconButton onClick={() => history.goBack()}><ChevronLeft /></IconButton>
            </Grid>
            <Grid item>
              <Typography variant="h3">{listing.name}</Typography>
            </Grid>
          </Grid>
          {/* <Grid item className={classes.itemHolder}>
            <CheckoutOrderType />
          </Grid> */}
          <Grid item className={classes.itemHolder}>
            <List>
              {/* <ListItem>
                <ListItemIcon>
                  <LocationOn color={"primary"} />
                </ListItemIcon>
                <ListItemText primary="3811 Millenia Blvd" secondary="Orlando, FL" />
                <ListItemSecondaryAction>
                  <Button edge="end" variant="contained" style={{ borderRadius: 500 }} color="primary">
                    Edit
                  </Button>
                </ListItemSecondaryAction>
              </ListItem> */}

              <ListItem>
                <ListItemIcon>
                  <Schedule color={"primary"} />
                </ListItemIcon>
                <ListItemText primary="Pickup Estimate" secondary={`${estimate?.delivery.prep.time || 0} minutes`} />
              </ListItem>

            </List>
          </Grid>
          <Grid container className={classes.itemHolder} direction="column" display="flex">
            <Grid item>

              <Typography variant="h5" style={{ flex: 1 }}>Payment</Typography>
            </Grid>
            <Grid item>
              <List>
                <ListItem>
                  <ListItemIcon>
                    <CreditCard color={"primary"} />
                  </ListItemIcon>
                  <ListItemText primary={cardSelected?.brand} secondary={cardSelected ? `Ending in ${cardSelected.last4} - ${cardSelected.expMonth}/${cardSelected.expYear}` : 'No card selected'} />
                  <ListItemSecondaryAction>
                    <Button edge="end" variant="contained" style={{ borderRadius: 500 }} color="primary" onClick={() => this.setState({ ccIsOpen: true })}>
                      Edit
                    </Button>
                  </ListItemSecondaryAction>
                </ListItem>
                <ListItem>
                  <ListItemIcon>
                    <LocalOffer color={"primary"} />
                  </ListItemIcon>
                  <ListItemText primary="Add Promo Code" />
                  <ListItemSecondaryAction>
                    <Button edge="end" variant="contained" style={{ borderRadius: 500 }} onClick={() => this.setState({ promoIsOpen: true })}>
                      Edit
                    </Button>
                  </ListItemSecondaryAction>
                </ListItem>
              </List>
            </Grid>
          </Grid>
          <Grid container className={classes.itemHolder} direction="column" display="flex">
            <Grid item>
              <Typography variant="h5" style={{ flex: 1 }}>Your Items</Typography>
            </Grid>
            <Grid container direction="column">
              {cart.items.length > 0 && cart.items.map((item, index) => (<ItemSection key={item._id + '-' + index} item={item} />))}
            </Grid>
          </Grid>
        </Grid>
        <Grid item className={classes.totalHolder} xs={12} md={6}>
          <Grid item className={classes.totalContainer}>
            <Grid item className={classes.totalBreakdownHolder}>
              <Typography>Subtotal</Typography>
              <Typography>{Intl.NumberFormat('en-US', { style: "currency", currency: "USD", minimumFractionDigits: 2, }).format((estimate?.charges?.subtotal / 100) || 0)}</Typography>
            </Grid>
            <Grid item className={classes.totalBreakdownHolder}>

              <Typography>Taxes & Fees</Typography>
              <Typography>{Intl.NumberFormat('en-US', { style: "currency", currency: "USD", minimumFractionDigits: 2, }).format(((estimate?.charges?.tax || 0) + (estimate?.charges?.fees || 0)) / 100)}</Typography>
            </Grid>
            {/* <Grid item className={classes.totalBreakdownHolder}>

              <Typography>Delivery Fees</Typography>
              <Typography>{Intl.NumberFormat('en-US', { style: "currency", currency: "USD", minimumFractionDigits: 2, }).format(cart?.charges?.tax / 100)}</Typography>
            </Grid> */}
            <Grid item className={classes.divider}>
              <Divider />
            </Grid>
            <Grid item className={classes.totalPrice}>
              <Typography variant="h6">Total</Typography>
              <Typography variant="h6">{Intl.NumberFormat('en-US', { style: "currency", currency: "USD", minimumFractionDigits: 2, }).format((estimate?.charges?.total / 100) || 0)}</Typography>
            </Grid>
            <Grid item className={classes.itemHolder}>
              <Button variant="contained" fullWidth color="primary" disabled={!cardSelected || !estimate || checkoutProcessing || !status?.open} className={classes.button} onClick={async () => {
                this.setState({ checkoutProcessing: true });
                try {
                  const obj = {
                    cartId: cart.cartId,
                    sourceId: cardSelected.id,
                    phone: phone,
                    type: 'pickup'
                  }

                  const response = await CartService.processCheckout(obj);
                  const newCart = await CartService.initializeCart();
                  CartStorage.cart = newCart;

                  history.replace(`confirmation/${response._id}`);
                  console.log(response);

                } catch (e) {
                  toast.error(e.response?.data?.message || 'Unable to process your payment at this time.');
                } finally {
                  this.setState({ checkoutProcessing: false });
                }
              }}>
                {checkoutProcessing && <CircularProgress size={18} />}
                {!checkoutProcessing && <Typography variant="button">{!status?.open ? 'Not Accepting Orders' : 'Place Order'}</Typography>}
              </Button>
            </Grid>
          </Grid>
        </Grid>
        <CreditCardDialog
          open={ccIsOpen}
          handleClose={() => { this.setState({ ccIsOpen: false }) }}
          selected={cardSelected}
          cards={cards}
          onCardSelected={async (cardId) => {
            let index = cards?.sources?.findIndex(c => c.id === cardId);
            if (index === -1) {
              const _cards = await UserService.cards();
              const card = _cards.sources.find(c => c.id === cardId);
              this.setState({ cardSelected: card, ccIsOpen: false, cards: _cards });
            } else {
              this.setState({ cardSelected: cards.sources[index], ccIsOpen: false });
            }
          }}
          onRemove={async (cardId) => {
            const response = await UserService.removeCard(cardId);

            if (response.success === true) {
              const _cards = await UserService.cards();

              this.setState({
                cards: _cards,
                cardSelected: _cards.sources.find(c => c.id === cards.defaultSource)
              });
            } else {
              toast.error('Unable to remove card. Try again later');
            }
          }}
        />
        <PhoneValidatorDialog open={phoneIsOpen} handleClose={async () => {
          await this.validatePhone();
          this.setState({ phoneIsOpen: false });
        }} />
        <PromoCodeDialog open={promoIsOpen} handleClose={() => { this.setState({ promoIsOpen: false }) }} />
      </Grid>
    );
  }
}

export default withStyles(theme => ({
  parentHolder: {
    minHeight: "calc(100vh)"
  },
  totalHolder: {
    backgroundColor: "rgb(246, 246, 246)",
    padding: "64px 32px 32px"
  },
  totalContainer: {
    position: "sticky",
    top: "64px",
  },
  checkoutHolder: {
    padding: "32px",
    [theme.breakpoints.down("xs")]: {
      padding: "4px",

    },
  },
  itemHolder: {
    padding: "16px 16px 8px"
  },
  button: {
    height: "56px",
  },
  totalBreakdownHolder: {
    padding: "4px 16px",
    flexDirection: "row",
    display: "flex",
    justifyContent: "space-between"
  },
  totalPrice: {
    padding: "16px",
    flexDirection: "row",
    display: "flex",
    justifyContent: "space-between",
  },
  divider: {
    padding: "16px 16px 0px",
  }
}))(withRouter(CheckoutPage));
