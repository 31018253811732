import { Button, Checkbox, CircularProgress, FormControlLabel, Grid, makeStyles, TextField, Typography } from '@material-ui/core';
import {
  CardCvcElement, CardExpiryElement, CardNumberElement, useElements, useStripe
} from "@stripe/react-stripe-js";
import React from 'react';
import UserService from '../../services/api/UserService';
import StripeInput from './StripeInput';

const useStyles = makeStyles(theme => ({
  addPaymentOption: {
    width: "100%",
    flexDirection: "column",
    display: "flex",
  },
  addPaymentOptionButton: {
    height: 48,
    marginTop: 8,
  },
  errorMessageHolder: {
    backgroundColor: theme.palette.error.main,
    borderRadius: theme.shape.borderRadius,
    marginLeft: 4,
    marginRight: 4
  },
  errorMessage: {
    color: theme.palette.white.main,
    paddingLeft: 8,
    paddingRight: 8,
    paddingTop: 4,
    paddingBottom: 4,
    fontSize: 14,
  }
}));

const CreditCardForm = ({ onCardSelected }) => {

  const [loading, setLoading] = React.useState(false);
  const [errors, setErrors] = React.useState({
    cardNumber: true,
    cardExpiry: true,
    cardCvc: true
  });
  const [cardIsDefault, setCardIsDefault] = React.useState(true);
  const [errorMessage, setErrorMessage] = React.useState(undefined);

  const classes = useStyles();
  const stripe = useStripe();
  const elements = useElements();

  const onCardError = (event) => {
    console.log(event);
    if (event.error) {
      setErrors(prevState => ({
        ...prevState,
        [event.elementType]: true
      }));
    } else {
      setErrors(prevState => ({
        ...prevState,
        [event.elementType]: false
      }));
    }
    setErrorMessage(undefined);
  }

  React.useEffect(() => {
    if (elements) {
      const cardNumberElement = elements?.getElement(CardNumberElement);
      cardNumberElement.on('change', onCardError);

      const cardExpiryElement = elements?.getElement(CardExpiryElement);
      cardExpiryElement.on('change', onCardError);

      const cardCvcElement = elements?.getElement(CardCvcElement);
      cardCvcElement.on('change', onCardError);
    }


  }, [elements]);

  if (!stripe || !elements) {
    return <></>;
  }

  return (
    <Grid container direction="column" style={{ paddingLeft: 16, paddingRight: 16 }}>
      <Grid item>
        <TextField
          label="Credit Card Number"
          name="ccnumber"
          required
          fullWidth
          margin="dense"
          disabled={loading}
          InputLabelProps={{ shrink: true }}
          InputProps={{
            inputComponent: StripeInput,
            disabled: loading,
            inputProps: {
              component: CardNumberElement
            },
          }}
        />
      </Grid>
      <Grid item>
        <TextField
          label="Expiration"
          name="ccExp"
          required
          fullWidth
          margin="dense"
          disabled={loading}
          InputLabelProps={{ shrink: true }}
          InputProps={{
            inputComponent: StripeInput,
            disabled: loading,
            inputProps: {
              component: CardExpiryElement
            },
          }}
        />
        <Grid item>
          <TextField
            label="Security Code"
            name="ccCvc"
            required
            fullWidth
            margin="dense"
            disabled={loading}
            InputLabelProps={{ shrink: true }}
            InputProps={{
              inputComponent: StripeInput,
              disabled: loading,
              inputProps: {
                component: CardCvcElement
              },
            }}
          />
        </Grid>
      </Grid>
      <Grid item>
        <FormControlLabel
          checked={cardIsDefault}
          onChange={event => setCardIsDefault(event.target.checked)}
          control={<Checkbox name="defaultMethod" />}
          label="Set as default payment method"
        />
      </Grid>

      {errorMessage && <Grid item className={classes.errorMessageHolder}>
        <Typography className={classes.errorMessage}>{errorMessage}</Typography>
      </Grid>}

      <Grid item className={classes.addPaymentOption}>
        <Button variant="contained" color="primary" startIcon={loading ? <CircularProgress size={18} /> : undefined} className={classes.addPaymentOptionButton} disabled={loading || errors.cardCvc || errors.cardExpiry || errors.cardNumber} onClick={async () => {
          setLoading(true);
          const cardElement = elements.getElement(CardNumberElement);
          const data = await stripe.createToken(cardElement);

          try {
            if (!data?.token?.id) {
              throw new Error('Unable to add card to your profile. Try again.');
            }

            const cardResponse = await UserService.addNewCard({ source: data.token.id, default: cardIsDefault });
            onCardSelected(cardResponse.card);
          } catch (e) {
            console.log(e);
            setErrorMessage("Invalid card number. Check and try again.");
            setLoading(false);
          }
        }}>
          Save
        </Button>
      </Grid>
    </Grid>
  );
}

export default CreditCardForm;

// class CreditCardForm extends Component {
//   state = {
//     loading: false,
//     errors: {
//       cardNumber: true,
//       cardExpiry: true,
//       cardCvc: true
//     },
//     cardIsDefault: true,
//     errorMessage: undefined
//   }

//   onCardError = (event) => {
//     if (event.error) {
//       this.setState({
//         errors: {
//           ...this.state.errors,
//           [event.elementType]: true
//         },
//         errorMessage: undefined
//       })
//     } else {
//       this.setState({
//         errors: {
//           ...this.state.errors,
//           [event.elementType]: false
//         },
//         errorMessage: undefined
//       })
//     }
//   }

//   componentDidMount() {
//     const { elements } = this.props;
//     const cardNumberElement = elements?.getElement(CardNumberElement);
//     cardNumberElement.on('change', this.onCardError);

//     const cardExpiryElement = elements?.getElement(CardExpiryElement);
//     cardExpiryElement.on('change', this.onCardError);

//     const cardCvcElement = elements?.getElement(CardCvcElement);
//     cardCvcElement.on('change', this.onCardError);

//   }
//   render() {
//     const { classes, onCardSelected } = this.props;
//     const { errorMessage, loading } = this.state;
//     return (
//       <Grid container direction="column" style={{ paddingLeft: 16, paddingRight: 16 }}>
//         <Grid item>
//           <TextField
//             label="Credit Card Number"
//             name="ccnumber"
//             required
//             fullWidth
//             margin="dense"
//             disabled={loading}
//             InputLabelProps={{ shrink: true }}
//             InputProps={{
//               inputComponent: StripeInput,
//               disabled: loading,
//               inputProps: {
//                 component: CardNumberElement
//               },
//             }}
//           />
//         </Grid>
//         <Grid item>
//           <TextField
//             label="Expiration"
//             name="ccExp"
//             required
//             fullWidth
//             margin="dense"
//             disabled={loading}
//             InputLabelProps={{ shrink: true }}
//             InputProps={{
//               inputComponent: StripeInput,
//               disabled: loading,
//               inputProps: {
//                 component: CardExpiryElement
//               },
//             }}
//           />
//           <Grid item>
//             <TextField
//               label="Security Code"
//               name="ccCvc"
//               required
//               fullWidth
//               margin="dense"
//               disabled={loading}
//               InputLabelProps={{ shrink: true }}
//               InputProps={{
//                 inputComponent: StripeInput,
//                 disabled: loading,
//                 inputProps: {
//                   component: CardCvcElement
//                 },
//               }}
//             />
//           </Grid>
//         </Grid>
//         <Grid item>
//           <FormControlLabel
//             checked={this.state.cardIsDefault}
//             onChange={event => this.setState({ cardIsDefault: event.target.checked })}
//             control={<Checkbox name="defaultMethod" />}
//             label="Set as default payment method"
//           />
//         </Grid>

//         {errorMessage && <Grid item className={classes.errorMessageHolder}>
//           <Typography className={classes.errorMessage}>{errorMessage}</Typography>
//         </Grid>}

//         <Grid item className={classes.addPaymentOption}>
//           <Button variant="contained" color="primary" startIcon={loading ? <CircularProgress size={18} /> : undefined} className={classes.addPaymentOptionButton} disabled={loading || this.state.errors.cardCvc || this.state.errors.cardExpiry || this.state.errors.cardNumber} onClick={async () => {
//             this.setState({ loading: true });
//             const { elements, stripe } = this.props;
//             const cardElement = elements.getElement(CardNumberElement);
//             const data = await stripe.createToken(cardElement);

//             try {
//               if (!data?.token?.id) {
//                 throw new Error('Unable to add card to your profile. Try again.');
//               }

//               const cardResponse = await UserService.addNewCard({ source: data.token.id, default: this.state.cardIsDefault });
//               onCardSelected(cardResponse.card);
//             } catch (e) {
//               console.log(e);
//               this.setState({ errorMessage: "Invalid card number. Check and try again.", loading: false });
//             }
//           }}>
//             Save
//           </Button>
//         </Grid>
//       </Grid>
//     );
//   }
// }

// export default withStyles(theme => ({
//   addPaymentOption: {
//     width: "100%",
//     flexDirection: "column",
//     display: "flex",
//   },
//   addPaymentOptionButton: {
//     height: 48,
//     marginTop: 8,
//   },
//   errorMessageHolder: {
//     backgroundColor: theme.palette.error.main,
//     borderRadius: theme.shape.borderRadius,
//     marginLeft: 4,
//     marginRight: 4
//   },
//   errorMessage: {
//     color: theme.palette.white.main,
//     paddingLeft: 8,
//     paddingRight: 8,
//     paddingTop: 4,
//     paddingBottom: 4,
//     fontSize: 14,
//   }
// }))(CreditCardForm);