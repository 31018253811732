import { ButtonBase, Dialog, Divider, Grid, Slide, Typography } from '@material-ui/core';
import { Close } from '@material-ui/icons';
import { Skeleton } from '@material-ui/lab';
import { withStyles } from '@material-ui/styles';
import React, { Component } from 'react';
import withMediaQuery from '../helpers/withMediaQuery';
import CartService from '../services/api/CartService';
import ListingService from '../services/api/ListingService';
import CartStorage from '../services/core/CartStorage';
import AddToOrderBar from './itemDetails/AddToOrderBar';
import MultipleModifiers from './itemDetails/MultipleModifiers';
import Notes from './itemDetails/Notes';
import SingleModifier from './itemDetails/SingleModifier';

class ItemDetails extends Component {
  state = {
    notes: '',
    quantity: 1,
    modifiers: {},
    total: 0,
    disabled: false,
    item: null,
    loading: true,
    updating: false
  }

  Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
  });

  async componentDidMount() {
    const { cartItem, itemId } = this.props;
    const item = await ListingService.getRestaurantItem(itemId);
    this.setState({
      ...this.state,
      item
    });
    const modifiers = {};
    let notes = "";
    let total = item.price.value;
    let quantity = 1;
    if (cartItem) {
      for (let m of item.modifiers) {
        const cm = cartItem.modifiers[m._id];
        if (cm) {
          modifiers[m._id] = m.single ? cm.options[0]._id : cm.options.map(option => option._id);
        } else {
          if (m.single) modifiers[m._id] = m.options[0]._id;
        }
      }
      notes = cartItem.notes;
      total = cartItem.charges.subtotal;
      quantity = cartItem.quantity;
    } else {
      for (let m of item.modifiers) {
        if (m.single) {
          modifiers[m._id] = m.options[0]._id;
          total += m.options[0].price;
        } else {
          modifiers[m._id] = [];
        }
      }
    }

    const disabled = this.isDisabled({ item, modifiers });

    this.setState({ ...this.state, modifiers, total, loading: false, disabled, notes, quantity });
  }

  isDisabled = ({ item, modifiers }) => {
    let disabled = false;
    let multiModifiers = item.modifiers.filter(m => m.single === false);
    for (let mm of multiModifiers) {
      if (mm.min > modifiers[mm._id]?.length) {
        disabled = true;
        break;
      }
    }

    return disabled;
  }

  calculateTotal = ({ item, modifiers, quantity }) => {
    let _total = item.price.value;

    for (let _mi in modifiers) {

      let modifier = item.modifiers.find((m) => m._id === _mi);
      if (modifier) {
        if (modifier.single) {
          let option = modifier.options.find(o => o._id === modifiers[_mi]);
          if (option) {
            _total += option.price;
          }
        } else {
          for (let _sm of modifiers[_mi]) {
            let option = modifier.options.find(o => o._id === _sm);
            if (option) {
              _total += option.price;
            }
          }
        }
      }

    }
    _total = _total * quantity;
    return _total;
  }

  onUpdateCartItem = async () => {

    this.setState({ updating: true })
    const { item, quantity, notes, modifiers } = this.state;
    const { categoryId, cartItem } = this.props;

    const obj = {
      item: {
        itemId: item._id,
        quantity,
        notes,
        modifiers,
      }
    }

    try {
      let cart = null;
      if (cartItem) {
        cart = await CartService.updateItemInCart(cartItem.cartItemId, obj);
      } else {
        obj.item.categoryId = categoryId;
        cart = await CartService.addItemToCart(obj);
      }

      CartStorage.cart = cart;

      this.props.handleClose();
    } catch (e) {
      console.log('error', e);
      this.setState({ updating: false });
    }
  }


  onClose = () => {

    this.props.handleClose();
    // setTimeout(() => {
    //   setState({ ...InitialState });
    // }, 500);
  }

  render() {

    const { Transition, onClose } = this;
    const { open, classes, isMobile = false } = this.props;
    const { loading, item } = this.state;

    return (
      <Dialog open={open} scroll="body" TransitionComponent={Transition} transitionDuration={500} fullScreen={isMobile} onClose={onClose} fullWidth={true} maxWidth="sm" keepMounted={false}>
        {loading ? <Skeleton height={400} className={classes.imageSkeleton} /> : (item?.images?.length > 0 && <img alt={item.name} className={classes.image} src={item.images[0].source} />)}
        <ButtonBase aria-label="close" className={classes.closeButton} onClick={onClose}>
          <Close fontSize="medium" />
        </ButtonBase>
        <Grid container className={classes.container} direction="column">
          <Grid item>
            <Typography variant="h1" className={classes.title}> {loading ? <Skeleton /> : item?.name}</Typography>
          </Grid>
          <Grid item>
            <Typography variant="caption">{loading ? <Skeleton /> : item?.description}</Typography>
          </Grid>
        </Grid>
        {
          item?.modifiers?.map((modifier, index) => {
            return <Grid key={'modifier-' + index} item>
              {modifier.single ?
                <SingleModifier modifier={modifier} onChange={(event) => {
                  const modifiers = {
                    ...this.state.modifiers,
                    [modifier._id]: event.target.value
                  }
                  const disabled = this.isDisabled({ item, modifiers });
                  const total = this.calculateTotal({ item, modifiers, quantity: this.state.quantity });

                  this.setState({
                    ...this.state, modifiers,
                    total,
                    disabled
                  })
                }} value={this.state.modifiers[modifier._id] || undefined} /> : <MultipleModifiers modifier={modifier} values={this.state.modifiers[modifier._id]} onClick={async (event) => {
                  let mods = this.state.modifiers[modifier._id] ? [...this.state.modifiers[modifier._id]] : [];
                  let index = mods.findIndex(m => m === event.target.id);
                  if (index !== -1) {
                    mods.splice(index, 1);
                  } else {
                    mods.push(event.target.id);
                  }

                  const modifiers = {
                    ...this.state.modifiers,
                    [modifier._id]: mods
                  }
                  const disabled = this.isDisabled({ item, modifiers });
                  const total = this.calculateTotal({ item, modifiers, quantity: this.state.quantity });
                  await this.setState({
                    ...this.state,
                    modifiers,
                    disabled,
                    total
                  });

                }} />
              }
              <Divider />
            </Grid>
          })
        }
        {
          !loading && (<><Grid item>
            <Notes value={this.state.notes} onChange={(event) => this.setState({ ...this.state, notes: event.target.value })} />
          </Grid>
            <Grid item>
              <AddToOrderBar updating={this.state.updating} disabled={this.state.disabled} total={this.state.total} quantity={this.state.quantity} onQuantityChanged={(type) => {
                let quantity = type === 'dec' ? this.state.quantity - 1 : this.state.quantity + 1;
                if (quantity <= 0) {
                  quantity = 1;
                }

                const total = this.calculateTotal({ item, modifiers: this.state.modifiers, quantity: quantity });
                this.setState({ ...this.state, quantity, total })
              }} onUpdateItem={this.onUpdateCartItem}
              />
            </Grid></>)
        }
      </Dialog>

    );
  }
}

export default withStyles(theme => ({
  appBar: {
    position: 'relative',
  },
  title: {
    fontSize: "36px",
    fontWeight: 500,
    lineHeight: "44px",
  },
  image: {
    height: 400,
    width: "100%",
    objectFit: "cover",
    [theme.breakpoints.down("sm")]: {
      height: '80vw'
    }
  },
  imageSkeleton: {
    height: 400,
    width: "100%",
    [theme.breakpoints.down("sm")]: {
      height: '80vw'
    },
    transform: "unset"
  },
  container: {
    padding: 16,
  },
  closeButton: {
    position: "absolute",
    top: 8,
    right: 8,
    backgroundColor: "#fff",
    height: 42,
    width: 42,
    borderRadius: 42,
  },
  loading: {
    width: "100%",
  }
}))(withMediaQuery([
  ['isMobile', theme => theme.breakpoints.down('sm'), {
    defaultMatches: true
  }]
])(ItemDetails));
