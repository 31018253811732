import React, { Component } from "react";
import { Redirect, Route, Switch, withRouter } from "react-router-dom";
import CartService from "../services/api/CartService";
import ListingService from "../services/api/ListingService";
import CartStorage from "../services/core/CartStorage";
import ListingStorage from "../services/core/ListingStorage";
import BrowsePage from "./core/BrowsePage";
import CheckoutPage from "./core/CheckoutPage";
import ConfirmationPage from "./core/ConfirmationPage";

class CorePage extends Component {
  state = {
    loading: true,
  };

  timerSetup() {
    const { order } = this.state;
    if (order?.tracking[order?.tracking.length - 1].status === 2) {
      if (this.statusTimeout) clearTimeout(this.statusTimeout);
    } else {
      this.statusTimeout = setTimeout(async () => {
        try {
          const status = await ListingService.getRestaurantStatus();
          ListingStorage.status = status;
        } finally {
          this.timerSetup();
        }
      }, 30000);
    }
  }

  async componentDidMount() {
    const { match: { params: { listingId } } } = this.props;
    const listing = await ListingService.getListingInformation(listingId);
    document.title = listing.name + " - Order Engine";
    ListingStorage.information = listing;

    const status = await ListingService.getRestaurantStatus();
    ListingStorage.status = status;

    const menu = await ListingService.getRestaurantMenu();
    ListingStorage.menu = menu;

    const expiration = CartStorage.expiration;
    if (expiration <= new Date() || !CartStorage.cart) {
      const cart = await CartService.initializeCart();
      CartStorage.cart = cart;
    }

    this.timerSetup();

    this.setState({ loading: false });
  }

  componentWillUnmount() {
    if (this.statusTimeout) clearTimeout(this.statusTimeout);
  }

  render() {
    const { url } = this.props.match;
    const { loading } = this.state;

    //TODO Replace with Loading Screen
    if (loading) {
      return <div></div>;
    }

    return (
      <Switch>
        <Route path={`${url}/browse`} component={BrowsePage} />
        <Route path={`${url}/checkout`} component={CheckoutPage} />
        <Route path={`${url}/confirmation/:orderId`} component={ConfirmationPage} />
        <Redirect from={url} to={`${url}/browse`} />
      </Switch>
    );
  }
}

export default withRouter(CorePage);
