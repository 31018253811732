import { Container, Grid, Typography } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import React, { Component } from "react";
import { withRouter } from "react-router";
import CategoryItem from "../../components/CategoryItem";
import ErrorMessage from "../../components/ErrorMessage";
import ImageCover from "../../components/ImageCover";
import InfoHeader from "../../components/InfoHeader";
import ItemDetails from "../../components/ItemDetails";
import TopBar from "../../components/TopBar";
import CartService from "../../services/api/CartService";
import CartStorage from "../../services/core/CartStorage";
import ListingStorage from "../../services/core/ListingStorage";
import SessionStorage from "../../services/core/SessionStorage";


class BrowsePage extends Component {
  state = {
    listing: ListingStorage.information,
    menu: ListingStorage.menu,
    status: ListingStorage.status,
    selected:
      ListingStorage.menu.categories &&
        ListingStorage.menu.categories.length > 0
        ? ListingStorage.menu.categories[0]
        : null,
    details: {
      open: false,
      itemId: null,
      cartItem: null,
      mounted: false,
      categoryId: null
    },
  };

  componentDidMount() {
    this.statusSubscription = ListingStorage.statusSub.subscribe(status => {
      this.setState({ status });
    });
  }

  componentWillUnmount() {
    if (this.statusSubscription) this.statusSubscription.unsubscribe();
  }

  render() {
    const { classes, history } = this.props;
    const { listing, menu, selected, details, status } = this.state;

    return (
      <div
        className={classes.container}
        onScroll={(event) => {
          const categories = [
            ...document.querySelectorAll("[header-id]"),
          ].filter(
            (c) =>
              c.offsetTop > event.nativeEvent.target.scrollTop - c.offsetHeight
          );
          const categoryId = categories[0].getAttribute("category-id");
          const _selected = menu.categories.find((c) => c._id === categoryId);
          if (selected?._id !== categoryId) {
            this.setState({ selected: _selected });
          }
        }}
      >
        <ImageCover src={listing?.cover} />
        <Grid container className={classes.headerContainer}>
          <Grid item xs={12}>
            <InfoHeader listing={listing} />
          </Grid>
          {/* <Grid item md={5} xs={12}>
            <OrderType />
          </Grid> */}
        </Grid>
        <TopBar
          category={selected}
          categories={menu.categories}
          onItemClicked={item => {
            this.setState({ details: { open: true, itemId: item._id, mounted: true, categoryId: item.category._id, cartItem: item } })
          }}
          onItemRemoved={async item => {
            const cart = await CartService.removeItemInCart(item.cartItemId);
            CartStorage.cart = cart;
          }}
          onCheckoutClicked={async () => {
            if (SessionStorage.session && SessionStorage.token) {
              const { data } = JSON.parse(Buffer.from(SessionStorage.token.split('.')[1], 'base64').toString());
              const { expiration } = JSON.parse(data);

              if (expiration < new Date().getTime()) {
                history.push(`/auth/login?redirect=${ListingStorage.information._id}/checkout`);
              } else {
                history.push('checkout');
              }
            } else {
              history.push(`/auth/login?redirect=${ListingStorage.information._id}/checkout`);
            }
          }}
          onCategorySelected={(category) => {
            const elem = document.querySelector(
              `[header-id=category-${category._id}]`
            );
            if (elem) {
              setTimeout(() => {
                elem.scrollIntoView({
                  block: "start",
                  inline: "start",
                });
              }, 100);
            }
          }}
        />
        {status && !status?.open && <ErrorMessage />}
        <Container className={classes.menuContainer}>
          {menu.categories.map((category) => (
            <Grid
              item
              key={category._id}
              header-id={"category-" + category._id}
              category-id={category._id}
              style={{ marginTop: 40, scrollMargin: 32 }}
            >
              <Typography variant="h6" className={classes.headerTitle}>
                {category.name}
              </Typography>
              <Grid container spacing={1}>
                {category.items.map((item) => (
                  <Grid item xs={12} md={6} key={item._id}>
                    <CategoryItem
                      item={item}
                      onClick={async () => {
                        await this.setState({ details: { open: true, itemId: item._id, mounted: true, categoryId: category._id } });
                      }}
                    />
                  </Grid>
                ))}
              </Grid>
            </Grid>
          ))}
        </Container>
        {this.state.details.mounted && <ItemDetails
          open={details.open}
          itemId={details.itemId}
          categoryId={details.categoryId}
          loading={details.loading}
          cartItem={details.cartItem}
          handleClose={() => {
            this.setState({ details: { ...this.state.details, open: false, itemId: null, cartItem: null, categoryId: null } });
            setTimeout(() => this.setState({ details: { ...this.state.details, mounted: false } }), 350);
          }}
        />}
      </div>
    );
  }
}

export default withStyles((theme) => ({
  container: {
    display: "flex",
    flexDirection: "column",
    height: "100vh",
    overflow: "scroll",
    position: "relative",
  },
  menuContainer: {
    paddingLeft: 16,
    paddingRight: 16,
    [theme.breakpoints.up("md")]: {
      paddingLeft: 38,
      paddingRight: 38,
    },
  },
  headerContainer: {
    padding: 16,
    [theme.breakpoints.up("md")]: {
      padding: 38,
    },
  },
  headerTitle: {
    marginTop: 16,
    marginBottom: 16,
  }
}), { withTheme: true })(withRouter(BrowsePage));
