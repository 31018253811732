import { Box, Button, ButtonBase, Divider, Grid, Paper, TextField, Typography, withStyles } from '@material-ui/core';
import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { toast } from 'react-toastify';
import SocialButton from '../../components/SocialButton';
import UserService from '../../services/api/UserService';
import SessionStorage from '../../services/core/SessionStorage';
import Environment from '../../system/Environment';

class LoginPage extends Component {
  state = {
    email: '',
    password: '',
    loading: false
  }

  loginUser = async () => {
    await this.setState({ loading: true });
    const { email, password } = this.state;
    const redirect = new URLSearchParams(this.props.location.search).get('redirect');
    try {
      const user = await UserService.login({ email, password, type: 'web' });
      SessionStorage.session = user;
      toast.success(`Welcome back ${user.name}!`);
      this.props.history.replace('/' + redirect);
    } catch (e) {
      console.log(e);
      toast.error('Unable to login. Please verify username and/or password.');
    } finally {
      await this.setState({ loading: false });
    }

  }

  enterKey = (event) => {
    if (event.key === 'Enter') {
      this.loginUser();
    }
  }

  handleSocialLogin = async (user) => {
    await this.setState({ loading: true });

    try {
      const redirect = new URLSearchParams(this.props.location.search).get('redirect');
      const res = await UserService.social({
        provider: user._provider,
        type: 'web',
        token: user._token.idToken
      });

      let session = {
        _id: res._id,
        name: res.name,
        email: res.email,
        avatar: res.avatar,
        superadmin: res.superadmin
      };

      SessionStorage.session = session;
      toast.success(`Welcome back ${session.name}!`);
      this.props.history.replace('/' + redirect);
    } catch (e) {
      console.log(e);
      toast.error('Unable to login. Please verify username and/or password.');
    } finally {
      await this.setState({ loading: false });
    }

  };

  handleSocialLoginFailure = (err) => {
    console.error(err);
  };

  render() {
    const { classes, history, location: { search } } = this.props;
    const { email, password } = this.state;

    return (
      <div className={classes.container}>
        <Paper className={classes.paper}>
          <Box className={classes.inner}>
            <Typography variant="h5" className={classes.title}>
              LOG IN
            </Typography><Typography className={classes.subtitle}>
              Enter your credentials
            </Typography>
            <TextField id="email" label="Email" type="email" value={email} onChange={event => this.setState({ email: event.target.value })} fullWidth className={classes.textField} onKeyDown={this.enterKey} />
            <TextField id="password" type="password" label="Password" value={password} onChange={event => this.setState({ password: event.target.value })} fullWidth className={classes.textField} onKeyDown={this.enterKey} />
            <Grid item className={classes.agreement}>
              <Typography variant="caption" >
                You agree to our <ButtonBase disableRipple><Typography className={classes.link} variant="caption">Terms of Service</Typography></ButtonBase> and <ButtonBase disableRipple><Typography className={classes.link} variant="caption">Privacy Policy</Typography></ButtonBase>.
              </Typography>
            </Grid>
            <Button variant="contained" fullWidth color="primary" className={classes.button} onClick={this.loginUser}>Continue</Button>
            <Button variant="text" fullWidth color="primary" className={classes.button} onClick={() => history.replace('/auth/forgot' + search)}>Can't Sign In</Button>
            <Box className={classes.orHolder}>
              <Typography variant="caption" className={classes.separator}>
                OR
              </Typography>
            </Box>
            <Box className={classes.socialHolder}>

              <SocialButton
                provider="google"
                appId={Environment.firebase.google.clientId}
                onLoginSuccess={this.handleSocialLogin}
                onLoginFailure={this.handleSocialLoginFailure}
              >
                <svg viewBox="0 0 366 372" width="25" height="25" xmlns="http://www.w3.org/2000/svg"><path d="M125.9 10.2c40.2-13.9 85.3-13.6 125.3 1.1 22.2 8.2 42.5 21 59.9 37.1-5.8 6.3-12.1 12.2-18.1 18.3l-34.2 34.2c-11.3-10.8-25.1-19-40.1-23.6-17.6-5.3-36.6-6.1-54.6-2.2-21 4.5-40.5 15.5-55.6 30.9-12.2 12.3-21.4 27.5-27 43.9-20.3-15.8-40.6-31.5-61-47.3 21.5-43 60.1-76.9 105.4-92.4z" id="Shape" fill="#EA4335" /><path d="M20.6 102.4c20.3 15.8 40.6 31.5 61 47.3-8 23.3-8 49.2 0 72.4-20.3 15.8-40.6 31.6-60.9 47.3C1.9 232.7-3.8 189.6 4.4 149.2c3.3-16.2 8.7-32 16.2-46.8z" id="Shape" fill="#FBBC05" /><path d="M361.7 151.1c5.8 32.7 4.5 66.8-4.7 98.8-8.5 29.3-24.6 56.5-47.1 77.2l-59.1-45.9c19.5-13.1 33.3-34.3 37.2-57.5H186.6c.1-24.2.1-48.4.1-72.6h175z" id="Shape" fill="#4285F4" /><path d="M81.4 222.2c7.8 22.9 22.8 43.2 42.6 57.1 12.4 8.7 26.6 14.9 41.4 17.9 14.6 3 29.7 2.6 44.4.1 14.6-2.6 28.7-7.9 41-16.2l59.1 45.9c-21.3 19.7-48 33.1-76.2 39.6-31.2 7.1-64.2 7.3-95.2-1-24.6-6.5-47.7-18.2-67.6-34.1-20.9-16.6-38.3-38-50.4-62 20.3-15.7 40.6-31.5 60.9-47.3z" fill="#34A853" /></svg>

              </SocialButton>
              <SocialButton
                provider="facebook"
                appId={Environment.firebase.facebook.appId}
                onLoginSuccess={this.handleSocialLogin}
                onLoginFailure={this.handleSocialLoginFailure}
              >
                <svg viewBox="0 0 33 33" width="25" height="25" xmlns="http://www.w3.org/2000/svg"><g><path d="M 17.996,32L 12,32 L 12,16 l-4,0 l0-5.514 l 4-0.002l-0.006-3.248C 11.993,2.737, 13.213,0, 18.512,0l 4.412,0 l0,5.515 l-2.757,0 c-2.063,0-2.163,0.77-2.163,2.209l-0.008,2.76l 4.959,0 l-0.585,5.514L 18,16L 17.996,32z" fill="#526dae"></path></g></svg>

              </SocialButton>
            </Box>
          </Box>
          <Divider />
          <Box className={classes.innerBottom}>
            <Typography variant="caption" align="center">
              Don't have an account?
            </Typography>
            <Button variant="text" fullWidth color="primary" onClick={() => history.replace('/auth/register' + search)}>Sign up</Button>
          </Box>
        </Paper>
      </div>
    );
  }
}

export default withStyles(theme => ({
  container: {
    display: "flex",
    justifyContent: "center",
    alignItems: "flex-start",
    backgroundColor: "rgb(246, 246, 246)"
  },
  paper: {
    margin: "48px 32px",
    width: 375,
    [theme.breakpoints.down("xs")]: {
      width: 300,
    }
  },
  inner: {
    padding: 32
  },
  innerBottom: {
    display: 'flex',
    flexDirection: 'column',
    padding: "24px 32px",
  },
  title: {
    marginBottom: 16,
    marginLeft: 16,
    marginRight: 16,
    fontWeight: "bold",
    textAlign: "center"
  },
  subtitle: {
    marginBottom: 16,
    marginLeft: 16,
    marginRight: 16,
    textAlign: "center",
    fontSize: 18,
  },
  agreement: {
    marginBottom: 32,
  },
  textField: {
    marginBottom: 16,
  },
  link: {
    fontWeight: 'bold'
  },
  button: {
    height: 56,
  },
  orHolder: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    margin: 16,
  },
  separator: {
    display: "inline-flex",
    position: "relative",
    padding: "0 8px",
    zIndex: "9999",
    fontWeight: "bold",
    fontSize: "14px",
    color: 'rgba(0,0,0,.3)',
    '&::before': {
      right: "100%",
      display: "block",
      content: "''",
      width: "30px",
      position: "absolute",
      top: "9px",
      borderTop: "1px solid",
    },
    "&::after": {
      left: "100%",
      content: "''",
      display: "block",
      width: "30px",
      position: "absolute",
      top: "9px",
      borderTop: "1px solid"
    }
  },
  socialHolder: {
    display: 'flex',
    justifyContent: "center",
    alignItems: 'center'
  },
  socialPaper: {
    justifyContent: 'center',
    alignItems: 'center',
    display: "flex",
  },

}))(withRouter(LoginPage));