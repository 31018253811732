import React, { Component } from "react";
import { Route, Switch, withRouter } from "react-router-dom";
import ListingStorage from "../services/core/ListingStorage";
import LoginPage from "./auth/LoginPage";
import RegisterPage from "./auth/RegisterPage";
import RequestPasswordPage from "./auth/RequestPasswordPage";

class AuthPage extends Component {
  state = {
    loading: false,
  };
  async componentDidMount() {
    const listing = ListingStorage.information;

    console.log(listing);
    this.setState({ loading: false });
  }

  render() {
    const { url } = this.props.match;
    const { loading } = this.state;

    //TODO Replace with Loading Screen
    if (loading) {
      return <div></div>;
    }

    return (
      <Switch>
        <Route path={`${url}/login`} component={LoginPage} />
        <Route path={`${url}/register`} component={RegisterPage} />
        <Route path={`${url}/forgot`} component={RequestPasswordPage} />
      </Switch>
    );
  }
}

export default withRouter(AuthPage);
