import axios from "axios";
import SessionStorage from "./core/SessionStorage";

class HttpClient {
  constructor() {
    this.axios = axios.create();
    this._initializeResponseInterceptor();
    this._initializeRequestInterceptor();
  }

  _initializeRequestInterceptor = () => {
    this.axios.interceptors.request.use(this._handleRequest, this._handleError);
  };

  _handleRequest = async (config) => {
    // const state = await store.getState();
    if (SessionStorage.token) {
      const token = SessionStorage.token;
      config.headers.authorization = token;
    }
    return config;
  };

  _initializeResponseInterceptor = () => {
    this.axios.interceptors.response.use(
      this._handleResponse,
      this._handleError
    );
  };

  _handleResponse = async ({ data, headers }) => {
    if (headers.authorization) {
      SessionStorage.token = headers.authorization;
    }
    return data;
  };

  _handleError = (error) => {
    if (error.response?.data?.statusCode === 401) {
      SessionStorage.session = undefined;
    }
    return Promise.reject(error)
  };
}

export default HttpClient;
