import { Button, CircularProgress, Grid, IconButton, Typography } from '@material-ui/core';
import { Remove, Add } from '@material-ui/icons'
import { makeStyles } from '@material-ui/styles';
import React from 'react';
import ListingStorage from '../../services/core/ListingStorage';

const useStyles = makeStyles(theme => ({
  holder: {
    paddingLeft: 16,
    paddingRight: 16,
    paddingBottom: 16,
    flexDirection: 'row',
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column"
    },
  },
  quantityHolder: {
    display: 'flex',
    alignItems: "center",
    justifyContent: "center",
    [theme.breakpoints.down("sm")]: {
      marginBottom: 16
    },
  },
  quantityText: {
    display: "inline-block",
    fontSize: 16,
    lineHeight: '24px',
    width: "32px",
    textAlign: 'center'
  },
  quantityButton: {
    backgroundColor: "#EEEEEE"
  },
  addToButtonHolder: {
    flex: 1,
    height: 56,
    paddingLeft: 16,
    justifyContent: 'center',
    alignItems: "center",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      paddingLeft: 0,
    },
  },
  addToButton: {
    height: "56px",
  },
  loading: {
    marginLeft: 16,
  }
}));

const AddToOrderBar = ({ quantity, total, onQuantityChanged, disabled, onUpdateItem, updating }) => {
  const classes = useStyles();
  const status = ListingStorage.status;

  return (
    <Grid container className={classes.holder}>
      <Grid item className={classes.quantityHolder}>
        <IconButton className={classes.quantityButton} onClick={() => onQuantityChanged('dec')}>
          <Remove />
        </IconButton>
        <Typography className={classes.quantityText}>
          {quantity}
        </Typography>
        <IconButton className={classes.quantityButton} onClick={() => onQuantityChanged('inc')}>
          <Add />
        </IconButton>
      </Grid>
      <Grid item className={classes.addToButtonHolder}>
        <Button disabled={disabled || updating || !status.open} onClick={onUpdateItem} variant={"contained"} color={"primary"} fullWidth className={classes.addToButton} disableElevation>
          {!updating && <Typography variant="button">
            Add {quantity} to order - {Intl.NumberFormat('en-US', { style: "currency", currency: "USD", minimumFractionDigits: 2, }).format(total / 100)}
          </Typography>}
          {updating && <CircularProgress
            className={classes.loading}
            size={20}
          />}
        </Button>
      </Grid>
    </Grid>
  );
}

export default AddToOrderBar;