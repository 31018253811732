import { Box, Button, Divider, Paper, TextField, Typography, withStyles } from '@material-ui/core';
import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { toast } from 'react-toastify';
import UserService from '../../services/api/UserService';

class RequestPasswordPage extends Component {
  state = {
    email: ''
  }
  render() {
    const { classes, history, location: { search } } = this.props;
    const { email } = this.state;
    return (
      <div className={classes.container}>
        <Paper className={classes.paper}>
          <Box className={classes.inner}>
            <Typography variant="h5" className={classes.title}>
              FORGOT PASSWORD
            </Typography><Typography className={classes.subtitle}>
              Enter your email
            </Typography>

            <TextField id="email" label="Email" fullWidth className={classes.textField} value={email} onChange={event => {
              this.setState({ email: event.target.value });
            }} />
            <Button variant="contained" fullWidth color="primary" className={classes.button} onClick={async () => {
              await UserService.forgot(this.state.email);
              toast.success(`A reset password email has been sent to the email address provided.`);
            }}>Continue</Button>
          </Box>
          <Divider />
          <Box className={classes.innerBottom}>
            <Typography variant="caption" align="center">
              Have an account?
            </Typography>
            <Button variant="text" fullWidth color="primary" onClick={() => history.replace('/auth/login' + search)}>Log in</Button>
          </Box>
        </Paper>
      </div>
    );
  }
}

export default withStyles(theme => ({
  container: {
    display: "flex",
    justifyContent: "center",
    alignItems: "flex-start",
    minHeight: "calc(100vh)",
    backgroundColor: "rgb(246, 246, 246)"
  },
  paper: {
    margin: "48px 32px",
    width: 375,
    [theme.breakpoints.down("xs")]: {
      width: 300,
    }
  },
  inner: {
    padding: 32
  },
  innerBottom: {
    display: 'flex',
    flexDirection: 'column',
    padding: "24px 32px",
  },
  title: {
    marginBottom: 16,
    marginLeft: 16,
    marginRight: 16,
    fontWeight: "bold",
    textAlign: "center"
  },
  subtitle: {
    marginBottom: 16,
    marginLeft: 16,
    marginRight: 16,
    textAlign: "center",
    fontSize: 18,
  },
  agreement: {
    marginBottom: 32,
  },
  textField: {
    marginBottom: 16,
  },
  link: {
    fontWeight: 'bold'
  },
  button: {
    height: 56,
    marginTop: 16,
  }

}))(withRouter(RequestPasswordPage));