import { Box, ButtonBase, Grid, Typography } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Menu from "@material-ui/core/Menu";
import { Close, RemoveShoppingCartOutlined, ShoppingCart } from "@material-ui/icons";
import { withStyles } from "@material-ui/styles";
import React, { Component } from "react";
import CartStorage from '../services/core/CartStorage';
import ListingStorage from "../services/core/ListingStorage";

class CartMenu extends Component {
  state = {
    anchorEl: null,
    cart: CartStorage.cart,
    status: ListingStorage.status,
  }

  componentDidMount() {
    this.cartSubject = CartStorage.cartSubject.subscribe(cart => {
      this.setState({ cart });
    });

    this.statusSubscription = ListingStorage.statusSub.subscribe(status => {
      this.setState({ status });
    });
  }

  componentWillUnmount() {
    if (this.cartSubject) this.cartSubject.unsubscribe();
    if (this.statusSubscription) this.statusSubscription.unsubscribe();
  }

  handleClick = (event) => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleClose = () => {
    this.setState({ anchorEl: null });
  };

  render() {
    const { anchorEl, cart, status } = this.state;
    const { handleClick, handleClose } = this;
    const { classes, onItemClicked, onItemRemoved, onCheckoutClicked } = this.props;

    let quantity = 0;
    cart?.items?.forEach(c => {
      quantity += c.quantity;
    });
    return (
      <>
        <Button
          className={classes.button}
          aria-controls="cart-menu"
          aria-haspopup="true"
          color={"primary"}
          startIcon={<ShoppingCart />}
          onClick={handleClick}
        >
          {quantity} items
        </Button>
        <Menu
          id="cart-menu"
          anchorEl={anchorEl}
          getContentAnchorEl={null}
          keepMounted
          TransitionComponent={this.Transition}
          anchorOrigin={{
            horizontal: "right",
            vertical: "bottom",
          }}
          variant="menu"
          open={Boolean(anchorEl)}
          onClose={handleClose}
        >
          <Box className={classes.mainContainer}>
            <Typography className={classes.orderTitle} variant="h4">Order</Typography>
            {cart.items.length > 0 && cart.items.map(item => (
              <ButtonBase key={item.cartItemId} className={classes.buttonBase} onClick={() => onItemClicked(item)}>
                <Grid container className={classes.itemContainer} direction="row" wrap="nowrap">
                  <Grid item>
                    <Typography className={classes.itemQuantity}>{item.quantity}</Typography>
                  </Grid>
                  <Grid container direction="column" className={classes.itemInfoContainer}>
                    <Grid item>
                      <Typography className={classes.itemTitle}>{item.name}</Typography>

                    </Grid>
                    <Grid item>
                      {item.subtext && <Typography className={classes.itemSubTitle}>{item.subtext}</Typography>}

                    </Grid>
                    <Grid item>
                      {item.notes && <Typography className={classes.itemNotes}>Notes: {item.notes}</Typography>}

                    </Grid>

                  </Grid>
                  <Grid item>
                    <Typography className={classes.itemPrice}>{Intl.NumberFormat('en-US', { style: "currency", currency: "USD", minimumFractionDigits: 2, }).format(item.charges.subtotal / 100)}</Typography>
                  </Grid>
                  <Grid item>
                    <Close fontSize="small" className={classes.itemRemove} onClick={(event) => {
                      event.stopPropagation();
                      onItemRemoved(item);
                    }} />
                  </Grid>
                </Grid>
              </ButtonBase>))}
            {cart.items.length > 0 && <Box className={classes.subTotalContainer}>

              <Typography className={classes.subTotalText}>Subtotal</Typography>
              <Typography className={classes.subTotalPrice}>{Intl.NumberFormat('en-US', { style: "currency", currency: "USD", minimumFractionDigits: 2, }).format(cart?.charges?.subtotal / 100)}</Typography>

            </Box>}
            {cart.items.length > 0 && <Box className={classes.checkoutButtonHolder}>
              <Button variant={"contained"} color={"primary"} className={classes.checkoutButton} fullWidth disableElevation onClick={onCheckoutClicked} disabled={!status.open}>
                Checkout
              </Button>
            </Box>}

            {cart.items.length === 0 && <Box className={classes.emptyHolder}>
              <RemoveShoppingCartOutlined className={classes.emptyHolderIcon} />
              <Typography variant="caption">There is nothing added. Why not add a few items.</Typography>
            </Box>}
          </Box>
        </Menu>
      </>
    );
  }
}

export default withStyles(theme => ({
  mainContainer: {
    width: "360px !important",
    maxWidth: "100%",
  },
  emptyHolder: {
    flexDirection: "column",
    display: "flex",
    padding: 16,
    justifyContent: "center",
    alignItems: "center"
  },
  emptyHolderIcon: {
    paddingBottom: 16
  },
  checkoutButtonHolder: {
    padding: "8px 16px"
  },
  checkoutButton: {
    width: "100%",
    height: "56px",
  },
  subTotalContainer: {
    padding: "24px 16px",
    display: "flex",
    flexDirection: "row",
  },
  subTotalText: {
    lineHeight: "20px",
    fontSize: "16px",
    fontWeight: "500",
    textAlign: "start",
    flex: 1,
  },
  subTotalPrice: {
    fontSize: "14px",
    fontWeight: "600",
  },
  orderTitle: {
    padding: 16,
  },
  button: {
    height: "100%",
    paddingLeft: 16,
    paddingRight: 16,
  },
  buttonBase: {
    width: "100%",
  },
  itemContainer: {
    cursor: "pointer",
    padding: 16,
    minHeight: "56px",
    borderBottomStyle: "solid",
    borderBottomWidth: 1,
    borderBottomColor: "rgba(217,219,224,.5)",
  },
  itemQuantity: {
    fontSize: "14px",
    fontWeight: "600",
  },
  itemInfoContainer: {
    padding: "0 20px",
    alignItems: "start",
    justifyContent: "flex-start",
    display: "flex",
    flexDirection: "column",
  },
  itemTitle: {
    lineHeight: "20px",
    fontSize: "16px",
    fontWeight: "500",
    textAlign: "start"
  },
  itemSubTitle: {
    lineHeight: "20px",
    fontSize: "14px",
    textAlign: "start"
  },
  itemNotes: {
    color: "#545454",
    lineHeight: "20px",
    fontSize: "12px",
    textAlign: "start"
  },
  itemPrice: {
    fontSize: "14px",
    fontWeight: "600",
  },
  itemRemove: {
    marginLeft: "8px",
    padding: 0,
  },
}))(CartMenu);
