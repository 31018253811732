import { Grid, makeStyles, TextField, Typography } from '@material-ui/core';
import React from 'react';

const useStyles = makeStyles(theme => (
  {
    holder: {
      marginTop: 16,
      marginBottom: 16,
      marginLeft: -8,
      marginRight: -8,
      paddingLeft: 24,
      paddingRight: 24,
    },
  }
))

const Notes = ({ onChange, value }) => {
  const classes = useStyles();
  if (!value) {
    value = ''
  }

  return (
    <Grid container className={classes.holder} direction="column" display="flex" spacing={1}>
      <Grid item>
        <Typography variant="h6">Notes</Typography>
      </Grid>
      <Grid item>
        <TextField
          value={value}
          fullWidth
          InputProps={{ disableUnderline: true }}
          placeholder={"Add Instructions..."}
          onChange={onChange}
          multiline={true}
          maxRows={4}
          minRows={4}
        />
      </Grid>
    </Grid>
  );
}

export default Notes;