import React, { Component } from 'react';
import { Box, Button, ButtonBase, CircularProgress, Dialog, DialogActions, Grid, Slide, TextField, Typography, withStyles } from '@material-ui/core';
import { Add, Close } from '@material-ui/icons';
import withMediaQuery from '../../helpers/withMediaQuery';

class PromoCodeDialog extends Component {
  state = {
    loading: false,
    error: false,
    promo: '',
  }

  Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
  });

  onClose = () => {
    this.props.handleClose();
  }

  render() {
    const { Transition, onClose } = this;
    const { open, isMobile = false, classes } = this.props;
    const { error, loading, promo } = this.state;

    return (
      <Dialog open={open} scroll="body" TransitionComponent={Transition} transitionDuration={500} fullScreen={isMobile} onClose={onClose} fullWidth={true} maxWidth="xs" keepMounted={false}>
        <ButtonBase aria-label="close" className={classes.closeButton} onClick={onClose}>
          <Close fontSize="medium" />
        </ButtonBase>
        <Grid container className={classes.container} direction="column" >
          <Grid item>
            <Typography variant="h1" className={classes.title}>Add Promo Code</Typography>
          </Grid>
          <Grid item className={classes.promoHolder}>
            <Typography>Enter your promotional code to redeem the offer</Typography>
          </Grid>
          <Grid item className={classes.promoHolder}>
            <TextField fullWidth placeholder='Promo Code' value={promo} onChange={event => this.setState({ promo: event.target.value, error: false, loading: false })} />
          </Grid>
          {error && <Grid item className={classes.promoHolder}>
            <Box className={classes.errorHolder}>
              <Typography className={classes.errorText}>Invalid promo code. Use a valid promo code.</Typography>
            </Box>
          </Grid>}
        </Grid>
        <DialogActions>
          <Button
            variant="contained"
            disableRipple
            color="primary"
            className={classes.addPromoCodeButton}
            startIcon={loading ? <CircularProgress size={18} /> : <Add />}
            disabled={loading || promo.trim().length === 0}
            onClick={() => {
              this.setState({ loading: true });
              setTimeout(() => {
                this.setState({ error: true, loading: false });
              }, 2000)
            }}>
            Add Promo
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

export default withStyles(theme => ({
  appBar: {
    position: 'relative',
  },
  title: {
    fontSize: "36px",
    fontWeight: 500,
    lineHeight: "44px",
    [theme.breakpoints.down('sm')]: {
      fontSize: "28px",
    }
  },
  image: {
    height: 400,
    width: "100%",
    objectFit: "cover",
    [theme.breakpoints.down("sm")]: {
      height: '80vw'
    }
  },
  imageSkeleton: {
    height: 400,
    width: "100%",
    [theme.breakpoints.down("sm")]: {
      height: '80vw'
    },
    transform: "unset"
  },
  container: {
    padding: 16,
  },
  closeButton: {
    position: "absolute",
    top: 8,
    right: 8,
    backgroundColor: "#fff",
    height: 42,
    width: 42,
    borderRadius: 42,
  },
  loading: {
    width: "100%",
  },
  promoHolder: {
    marginTop: 16,
  },
  errorHolder: {
    justityContent: "center",
    alignItems: "center",
    padding: 16,
    backgroundColor: theme.palette.error.main,
    position: "sticky",
    top: 56,
    [theme.breakpoints.up("sm")]: {
      top: 64

    },
    borderRadius: 16,
    zIndex: 10,
  },
  errorText: {
    color: "#fff",
    textAlign: "center"
  }
}))(withMediaQuery([
  ['isMobile', theme => theme.breakpoints.down('sm'), {
    defaultMatches: true
  }]
])(PromoCodeDialog));