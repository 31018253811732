import React, { Component } from "react";
import Button from "@material-ui/core/Button";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import { Menu as MenuIcon } from "@material-ui/icons";
import { withStyles } from "@material-ui/styles";

class CategoryMenu extends Component {
  state = {
    anchorEl: null,
  }

  handleClick = (event) => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleClose = (category) => {
    this.setState({ anchorEl: null });
    this.props.onSelected(category);
  };

  render() {
    const { anchorEl } = this.state;
    const { handleClick, handleClose } = this;
    const { classes, category, categories } = this.props;
    return (
      <>
        <Button
          className={classes.button}
          aria-controls="simple-menu"
          aria-haspopup="true"
          startIcon={<MenuIcon />}
          onClick={handleClick}
        >
          {category.name}
        </Button>
        <Menu
          id="simple-menu"
          anchorEl={anchorEl}
          getContentAnchorEl={null}
          keepMounted
          variant="menu"
          anchorOrigin={{
            horizontal: "left",
            vertical: "bottom",
          }}
          open={Boolean(anchorEl)}
          onClose={handleClose}
        >
          {categories.map(c => (<MenuItem key={c._id} selected={category._id === c._id ? true : false} onClick={() => handleClose(c)}>{c.name}</MenuItem>))}
        </Menu>
      </>
    );
  }
}

export default withStyles(() => ({
  button: {
    height: "100%",
    paddingLeft: 16,
    paddingRight: 16,
  },
}))(CategoryMenu);
