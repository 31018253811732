import { Divider, Grid, List, ListItem, ListItemIcon, ListItemText, Typography, withStyles } from "@material-ui/core";
import { Business, Schedule } from "@material-ui/icons";
import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import ItemSection from "../../components/CheckoutPage/ItemSection";
import Tracker from "../../components/ConfirmationPage/Tracker";
import CartService from "../../services/api/CartService";
import ListingStorage from "../../services/core/ListingStorage";

class ConfirmationPage extends Component {
  state = {
    order: null
  }

  async init() {

    console.log(this.props.match.params);
    const orderId = this.props.match.params.orderId;
    const listingId = ListingStorage.information._id;
    try {
      const order = await CartService.getOrder(orderId);
      this.setState({ order });
    } catch (e) {
      const { history } = this.props;
      history.replace(`/auth/login?redirect=${listingId}/confirmation/${orderId}`);
    }
  }

  componentDidMount() {
    this.init();
    this.timerSetup();
  }

  componentWillUnmount() {
    if (this.timeout) clearTimeout(this.timeout);
  }

  timerSetup() {
    const { order } = this.state;
    if (order?.tracking[order?.tracking.length - 1].status === 2) {
      if (this.timeout) clearTimeout(this.timeout);
    } else {
      this.timeout = setTimeout(async () => {
        try {
          this.init();
        } finally {
          this.timerSetup();
        }
      }, 10000);
    }
  }

  render() {
    const { classes } = this.props;
    const { order } = this.state;

    const tracking = order?.tracking;


    return (
      <Grid container className={classes.parentHolder}>
        <Grid container display="flex" direction="column" wrap="nowrap" className={classes.confirmationHolder} item xs={12} md={6}>
          <Grid container justifyContent="flex-start" alignItems="center" wrap="nowrap" spacing={1}>
            <Grid item>
              <Typography variant="h4">Thank you for your order</Typography>
            </Grid>
          </Grid>
          <Grid container className={classes.itemHolder} direction="column" display="flex">
            <Grid item>
              <List>
                <ListItem>
                  <ListItemIcon>
                    <Business color={"primary"} />
                  </ListItemIcon>
                  <ListItemText primary={order?.listing?.name} secondary={order?.listing.location.formatted_address} />
                </ListItem>
                <ListItem>
                  <ListItemIcon>
                    <Schedule color={"primary"} />
                  </ListItemIcon>
                  <ListItemText primary="Pickup" secondary={`${order?.delivery.prep.time || 0} minutes`} />
                </ListItem>
                <Typography variant="h5" className={classes.middleHeader}>Payment</Typography>
                <ListItem>
                  <ListItemIcon>
                    {order && <img className={classes.card} alt={order?.payment?.card.brand} src={require(`../../assets/images/cards/${order?.payment?.card.brand}.png`).default} />}
                  </ListItemIcon>
                  <ListItemText primary={order?.payment?.card?.brand} secondary={`Ending in ${order?.payment?.card.last4} - Expiration ${order?.payment?.card.expMonth}/${order?.payment?.card.expYear}`} />
                </ListItem>
              </List>
            </Grid>
            <Grid item>
              <Typography variant="h5" className={classes.middleHeader}>Tracking</Typography>
              <Tracker tracking={tracking} currentTracking={order?.currentTracking} />
            </Grid>
          </Grid>
        </Grid>
        <Grid item className={classes.totalHolder} xs={12} md={6}>
          <Grid container className={classes.itemHolder} direction="column" display="flex">
            <Grid item>
              <Typography variant="h5" style={{ flex: 1 }}>Your Order</Typography>
            </Grid>
            <Grid container direction="column">
              {order?.cart.items.length > 0 && order?.cart.items.map((item, index) => (<ItemSection key={item._id + '-' + index} item={item} />))}
            </Grid>
          </Grid>
          <Grid item className={classes.totalContainer}>
            <Grid item className={classes.totalBreakdownHolder}>
              <Typography>Subtotal</Typography>
              <Typography>{Intl.NumberFormat('en-US', { style: "currency", currency: "USD", minimumFractionDigits: 2, }).format((order?.charges?.subtotal / 100) || 0)}</Typography>
            </Grid>
            <Grid item className={classes.totalBreakdownHolder}>

              <Typography>Taxes & Fees</Typography>
              <Typography>{Intl.NumberFormat('en-US', { style: "currency", currency: "USD", minimumFractionDigits: 2, }).format(((order?.charges?.tax || 0) + (order?.charges?.fees || 0)) / 100)}</Typography>
            </Grid>
            <Grid item className={classes.divider}>
              <Divider />
            </Grid>
            <Grid item className={classes.totalPrice}>
              <Typography variant="h6">Total</Typography>
              <Typography variant="h6">{Intl.NumberFormat('en-US', { style: "currency", currency: "USD", minimumFractionDigits: 2, }).format((order?.charges?.total / 100) || 0)}</Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  }
}

export default withStyles(theme => ({
  card: {
    height: 24,
  },
  parentHolder: {
    minHeight: "calc(100vh)"
  },
  totalHolder: {
    backgroundColor: "rgb(246, 246, 246)",
    padding: "64px 32px 32px"
  },
  totalContainer: {
    position: "sticky",
    top: "64px",
  },
  confirmationHolder: {
    padding: "32px",
    [theme.breakpoints.down("xs")]: {
      padding: "16px",

    },
  },
  itemHolder: {
    paddingTop: 16,
    paddingBottom: 16,
  },
  button: {
    height: "56px",
  },
  totalBreakdownHolder: {
    padding: "4px 16px",
    flexDirection: "row",
    display: "flex",
    justifyContent: "space-between"
  },
  totalPrice: {
    padding: "16px",
    flexDirection: "row",
    display: "flex",
    justifyContent: "space-between",
  },
  divider: {
    padding: "16px 16px 0px",
  },
  middleHeader: {
    marginTop: 16,
  }
}))(withRouter(ConfirmationPage));
