import { Button, CircularProgress, Dialog, FormControl, Grid, Input, Slide, Typography, withStyles } from '@material-ui/core';
import React, { Component } from 'react';
import MaskedInput from 'react-text-mask';
import withMediaQuery from '../../helpers/withMediaQuery';
import UserService from '../../services/api/UserService';


class PhoneValidatorDialog extends Component {

  state = {
    loading: false,
    error: false,
    phone: '',
    verificationError: false,
    showCodeVerification: false,
    verificationCode: ''
  }

  Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
  });

  async componentDidMount() {

  }


  onClose = (event, reason) => {
    if (reason === 'backdropClick') return;
  }

  TextMaskCustom(props) {
    const { inputRef, ...other } = props;

    return (
      <MaskedInput
        {...other}
        ref={(ref) => {
          inputRef(ref ? ref.inputElement : null);
        }}
        mask={['(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
        placeholderChar={'x'}
        showMask
      />
    );
  }

  render() {
    const { Transition, onClose } = this;
    const { open, classes, isMobile = false } = this.props;
    const { loading, phone, error, verificationError, showCodeVerification, verificationCode } = this.state;

    return (
      <Dialog open={open} scroll="body" TransitionComponent={Transition} transitionDuration={500} fullScreen={isMobile} onClose={onClose} disablePortal={true} disableEscapeKeyDown={true} fullWidth={true} maxWidth="xs" keepMounted={false}>
        <Grid container className={classes.container} direction="column">
          <Grid item>
            <Typography align="center" variant="h5" className={classes.title}>Add your phone number</Typography>
          </Grid>

          <Grid item>
            <Typography align="center" className={classes.phoneInfo}>Your phone number is use to send status updates of your order.</Typography>
          </Grid>
          <Grid item className={classes.phoneInputHolder}>
            {!showCodeVerification && <FormControl>
              <Input
                error={error}
                onChange={(event) => {
                  const value = event.target.value;
                  this.setState({ phone: value });
                  if (!/^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]\d{3}[\s.-]\d{4}$/.test(value)) {
                    if (!error)
                      this.setState({ error: true })
                  } else {
                    this.setState({ error: false })
                  }
                }}
                value={phone}
                name="textmask"
                inputProps={{
                  style: {
                    textAlign: "center"
                  }
                }}
                inputComponent={this.TextMaskCustom}
                className={classes.phoneInput}
              />
            </FormControl>}
            {showCodeVerification &&
              <FormControl>
                <Input
                  placeholder='CODE'
                  onChange={(event) => {
                    this.setState({ verificationCode: event.target.value })
                  }}
                  inputProps={{
                    style: {
                      textAlign: 'center'
                    }
                  }}
                  value={verificationCode}
                  className={classes.phoneInput}
                />
              </FormControl>
            }
          </Grid>
          {verificationError && <Grid item className={classes.errorMessageHolder}>
            <Typography className={classes.errorMessage}>Unable to verify this phone number.</Typography>
          </Grid>}
          <Grid item className={classes.submitButtonHolder}>
            <Button
              variant="contained"
              color="primary"
              disabled={loading || (!showCodeVerification ? !/^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]\d{3}[\s.-]\d{4}$/.test(phone) : verificationCode.trim().length === 0)}
              className={classes.submitButton}
              onClick={async () => {
                this.setState({ loading: true });
                try {
                  if (showCodeVerification) {
                    const response = await UserService.verifyPhoneVerificationCode(verificationCode);
                    if (response.success) {
                      this.props.handleClose();
                    }
                  } else {
                    const response = await UserService.sendPhoneForVerification(phone.replace(/\D/g, ''));
                    if (response.success) {
                      this.setState({ showCodeVerification: true })
                    }
                  }

                } catch (e) {
                  this.setState({ verificationError: true })
                } finally {
                  this.setState({ loading: false })
                }
              }}>
              {loading && <CircularProgress />}
              {showCodeVerification ? "VERIFY" : "SUBMIT"}
            </Button>
          </Grid>
        </Grid>
      </Dialog>
    );
  }
}

export default withStyles(theme => ({
  appBar: {
    position: 'relative',
  },
  title: {
    fontSize: "36px",
    fontWeight: 500,
    lineHeight: "44px",
  },
  container: {
    padding: 16,
  },
  closeButton: {
    position: "absolute",
    top: 8,
    right: 8,
    backgroundColor: "#fff",
    height: 42,
    width: 42,
    borderRadius: 42,
  },
  phoneInfo: {
    marginRight: 32,
    marginLeft: 32,
    marginTop: 24,
    marginBottom: 24,
  },
  phoneInput: {
    fontSize: 24,
    textAlign: "center"
  },
  phoneInputHolder: {
    justifyContent: "center",
    alignItems: "center",
    display: "flex"
  },
  submitButtonHolder: {
    justifyContent: "center",
    alignItems: "center",
    display: "flex",
    marginTop: 24
  },
  submitButton: {
    width: 250,
    fontSize: 18
  },
  loading: {
    width: "100%",
  },
  errorMessageHolder: {
    backgroundColor: theme.palette.error.main,
    borderRadius: theme.shape.borderRadius,
    marginLeft: 16,
    marginRight: 16,
    marginTop: 8,
  },
  errorMessage: {
    color: theme.palette.white.main,
    paddingLeft: 8,
    paddingRight: 8,
    paddingTop: 4,
    paddingBottom: 4,
    fontSize: 16,
  }
}))(withMediaQuery([
  ['isMobile', theme => theme.breakpoints.down('sm'), {
    defaultMatches: true
  }]
])(PhoneValidatorDialog));