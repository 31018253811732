import { createTheme, makeStyles, ThemeProvider } from "@material-ui/core";
import { BrowserRouter as Router, Redirect, Route, Switch } from "react-router-dom";
import AuthPage from "./pages/AuthPage";
import CorePage from "./pages/CorePage";
import HealthPage from './pages/HealthPage';
import RecentOrdersPage from './pages/RecentOrdersPage';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const theme = createTheme({
  palette: {
    primary: {
      main: "#000",
    },
    secondary: {
      main: '#000',
    },
    white: {
      main: "#fff"
    },
    black: "#000",
    error: {
      main: "#bb2124"
    }
  },
  shape: {
    borderRadius: 8
  },
  typography: {
    fontFamily: "'Mulish', sans-serif"
  }
});

const useStyles = makeStyles(theme => ({
  toast: {
    '--toastify-font-family': "Mulish, Helvetica, Arial, sans-serif",
  }
}));


function App() {
  const classes = useStyles();
  return (
    <ThemeProvider theme={theme}>
      <Router>
        <Switch>
          <Redirect strict from="/order-engine/:listingId/restaurants/browse" to="/:listingId/browse" />
          <Route path="/health" component={HealthPage} />
          <Route path="/auth" component={AuthPage} />
          <Route path="/recent-orders" component={RecentOrdersPage} />
          <Route path="/:listingId" component={CorePage} />
        </Switch>
      </Router>
      <ToastContainer pauseOnHover={false} pauseOnFocusLoss={false} className={classes.toast} />
    </ThemeProvider>
  );
}

export default App;
