import { Chip, Grid, Typography } from '@material-ui/core';
import { LocationOn } from '@material-ui/icons';
import { makeStyles } from '@material-ui/styles';
import React from 'react';

const useStyles = makeStyles(theme => ({
  multiLineEllipsis: {
    overflow: "hidden",
    textOverflow: "ellipsis",
    display: "-webkit-box",
    "-webkit-line-clamp": 4,
    "-webkit-box-orient": "vertical"
  },
  container: {
    display: "flex",
    flexDirection: "column"
  }
}));

const InfoHeader = ({ listing }) => {
  const classes = useStyles();

  return (
    <Grid container spacing={1} className={classes.container}>
      <Grid item>
        <Typography variant="h4" component="h1">{listing.name}</Typography>
      </Grid>
      <Grid item>
        <Typography variant="caption" className={classes.multiLineEllipsis}>{listing.description}</Typography>
      </Grid>
      <Grid item>
        <Chip color="primary" icon={<LocationOn fontSize="small" />} label={listing.location.formatted_address}></Chip>
      </Grid>
    </Grid>
  );
}

export default InfoHeader;