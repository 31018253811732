import { Button, ButtonBase, Dialog, Grid, makeStyles, Slide, Typography, useMediaQuery, useTheme } from '@material-ui/core';
import { Add, ChevronLeft, Close, Edit } from '@material-ui/icons';
import {
  Elements
} from "@stripe/react-stripe-js";
import { loadStripe } from '@stripe/stripe-js';
import React from 'react';
import Environment from '../../system/Environment';
import CreditCardForm from './CreditCardForm';
import CreditCardItem from './CreditCardItem';

const stripePromise = loadStripe(Environment.stripe.key);

const useStyles = makeStyles(theme => ({
  appBar: {
    position: 'relative',
  },
  title: {
    fontSize: "36px",
    fontWeight: 500,
    lineHeight: "44px",
    [theme.breakpoints.down('sm')]: {
      fontSize: "28px",
    }
  },
  image: {
    height: 400,
    width: "100%",
    objectFit: "cover",
    [theme.breakpoints.down("sm")]: {
      height: '80vw'
    }
  },
  imageSkeleton: {
    height: 400,
    width: "100%",
    [theme.breakpoints.down("sm")]: {
      height: '80vw'
    },
    transform: "unset"
  },
  container: {
    padding: 16,
  },
  closeButton: {
    position: "absolute",
    top: 8,
    right: 8,
    backgroundColor: "#fff",
    height: 42,
    width: 42,
    borderRadius: 42,
  },
  editButton: {
    position: "absolute",
    top: 8,
    right: 52,
    backgroundColor: "#fff",
    height: 42,
    width: 42,
    borderRadius: 42,
  },
  addPaymentOption: {
    padding: 16,
    width: "100%",
    flexDirection: "column",
    display: "flex",
  },
  addPaymentOptionButton: {
    height: 48,
  },
  loading: {
    width: "100%",
  }
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const CreditCardDialog = ({ cards, open, selected, onCardSelected, onRemove, handleClose }) => {
  const classes = useStyles();
  const [newPaymentPrompt, setNewPaymentPrompt] = React.useState(false);
  const [editToggled, setEditToggled] = React.useState(false);
  const theme = useTheme();

  const isMobile = useMediaQuery(theme.breakpoints.down("xs"), {
    noSsr: true
  });

  React.useEffect(() => {
    if (cards?.sources.length === 0) {
      setNewPaymentPrompt(true);
    }
  }, [cards])

  const onClose = () => {
    handleClose();
    setTimeout(() => setEditToggled(false), 300);
  }

  const onEdit = () => {
    setEditToggled(!editToggled);
  }

  return (
    <Dialog open={open} scroll="body" TransitionComponent={Transition} transitionDuration={500} fullScreen={isMobile} onClose={onClose} fullWidth={true} maxWidth="xs" keepMounted={false}>
      <ButtonBase aria-label="close" className={classes.closeButton} onClick={onClose}>
        <Close fontSize="medium" />
      </ButtonBase>
      {!newPaymentPrompt && <ButtonBase aria-label="edit" className={classes.editButton} onClick={onEdit}>
        <Edit fontSize="small" />
      </ButtonBase>}
      <Grid container className={classes.container} direction="row" alignItems="center" justifyContent='space-between'>
        <Grid item>
          <Typography variant="h1" className={classes.title}>{editToggled ? "Edit Payments" : "Payment Options"}</Typography>
        </Grid>
      </Grid>
      {!newPaymentPrompt && <Grid container direction="column">
        {cards?.sources?.map(card => (
          <CreditCardItem editToggled={editToggled} onCardSelected={onCardSelected} card={card} selected={selected} onRemove={onRemove} key={card.id} />
        ))}

      </Grid>}
      {newPaymentPrompt && (
        <Elements stripe={stripePromise}>
          <CreditCardForm onCardSelected={(card) => {
            onCardSelected(card);
            setNewPaymentPrompt(!newPaymentPrompt);
          }} />
        </Elements>
      )}
      <Grid container direction="column" className={classes.addPaymentOption}>
        {!editToggled && <Button variant={newPaymentPrompt ? "text" : "contained"} disableRipple color="primary" className={classes.addPaymentOptionButton} startIcon={newPaymentPrompt ? <ChevronLeft /> : <Add />} onClick={() => setNewPaymentPrompt(!newPaymentPrompt)}>
          {newPaymentPrompt ? "Go Back" : "Add Payment Method"}
        </Button>}
      </Grid>
    </Dialog>
  );
}

export default CreditCardDialog;