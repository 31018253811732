import ListingStorage from "./ListingStorage";
import { Subject } from 'rxjs';

class CartStorage {
  cartSubject = new Subject();
  get cart() {
    return JSON.parse(localStorage.getItem(`restaurant-cart-${ListingStorage.information._id}`));
  }

  set cart(_cart) {
    localStorage.setItem(`restaurant-cart-${ListingStorage.information._id}`, JSON.stringify(_cart));
    localStorage.setItem(`restaurant-cart-${ListingStorage.information._id}-expiration`, new Date().getTime() + (60 * 24 * 24 * 1000));
    this.cartSubject.next(_cart);
  }

  get expiration() {
    return localStorage.getItem(`restaurant-cart-${ListingStorage.information._id}-expiration`);
  }
}

export default new CartStorage();
