import { Subject } from 'rxjs';

class SessionStorage {
  sessionSubject = new Subject();
  tokenSubject = new Subject();
  get session() {
    return JSON.parse(localStorage.getItem(`nm-oe-session-key`));
  }

  set session(_data) {
    if (!_data) {
      localStorage.removeItem(`nm-oe-session-key`);
    } else {
      localStorage.setItem(`nm-oe-session-key`, JSON.stringify(_data));
    }
    this.sessionSubject.next(_data);
  }

  get token() {
    return JSON.parse(localStorage.getItem(`nm-oe-session-token`));
  }

  set token(_token) {
    localStorage.setItem(`nm-oe-session-token`, JSON.stringify(_token));
    this.tokenSubject.next(_token);
  }
}

export default new SessionStorage();
