import Environment from "../../system/Environment";
import SessionStorage from "../core/SessionStorage";
import HttpService from "../HttpService";

class UserService extends HttpService {
  register(obj) {
    return this.axios.post(`${Environment.api.baseUrl}/v2/users`, obj);
  }

  login(obj) {
    return this.axios.post(
      `${Environment.api.baseUrl}/v2/users/auth`,
      obj
    );
  }

  social(obj) {
    return this.axios.post(
      `${Environment.api.baseUrl}/v2/users/auth/social`,
      obj
    );
  }

  forgot(email) {
    return this.axios.post(
      `${Environment.api.baseUrl}/v1/users/auth/reset`,
      { email }
    );
  }

  cards() {
    const userId = SessionStorage.session._id;
    return this.axios.get(
      `${Environment.merchantApi.baseUrl}/v1/users/${userId}/cards`
    );
  }

  addNewCard(obj) {
    const userId = SessionStorage.session._id;
    return this.axios.post(
      `${Environment.merchantApi.baseUrl}/v1/users/${userId}/cards`, obj
    );
  }

  removeCard(cardId) {
    const userId = SessionStorage.session._id;
    return this.axios.delete(
      `${Environment.merchantApi.baseUrl}/v1/users/${userId}/cards/${cardId}`);
  }

  getPhoneNumber() {
    const userId = SessionStorage.session._id;
    return this.axios.get(
      `${Environment.api.baseUrl}/v1/users/${userId}/profile/phone`);
  }

  sendPhoneForVerification(phone) {
    const userId = SessionStorage.session._id;
    return this.axios.patch(
      `${Environment.api.baseUrl}/v1/users/${userId}/profile/phone`, { phone: '+1' + phone });
  }

  verifyPhoneVerificationCode(code) {
    const userId = SessionStorage.session._id;
    return this.axios.patch(
      `${Environment.api.baseUrl}/v1/users/${userId}/profile/phone/verify`, { code });
  }

  getAllUsersOrders() {
    return this.axios.get(
      `${Environment.merchantApi.baseUrl}/v1/users/${SessionStorage.session._id}/orders?limit=100&page=1`
    );
  }

}

export default new UserService();
