import { Box, Typography } from "@material-ui/core";

const HealthPage = () => {
  return (
    <Box>
      <Typography>NM Restaurant</Typography>
      <Typography>Status: Up</Typography>

    </Box>
  );
}

export default HealthPage;