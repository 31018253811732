import React from "react";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(() => ({
  image: {
    objectFit: "cover",
    width: "100%",
    height: "300px",
  },
}));

const ImageCover = (props) => {
  const classes = useStyles();

  return <img className={classes.image} {...props} alt={""} />;
};

export default ImageCover;
