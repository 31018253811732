import { Checkbox, FormControlLabel, Grid, makeStyles, Typography } from '@material-ui/core';
import React from 'react';

const useStyles = makeStyles(theme => (
  {
    holder: {
      marginTop: 16,
      marginBottom: 16,
      marginLeft: -8,
      marginRight: -8,
      paddingLeft: 24,
      paddingRight: 24,
      "& .MuiFormControlLabel-label": {
        width: "100%"
      }
    },
    price: {
      color: "#8f95a3",
      fontSize: "14px",
    }
  }
))

const MultipleModifiers = ({ modifier, values, onClick }) => {
  const classes = useStyles();
  if (!values) {
    values = [];
  }

  let { min, max } = modifier;
  let subTitle = '';
  if (min > 0 && max > 0) {
    subTitle = `Required • Choose between ${min} and ${max}`;
  } else if (min > 0) {
    subTitle = `Required • Choose at least ${min}`;
  } else if (max > 0) {
    subTitle = `Choose up to ${max}`
  } else {
    subTitle = `Choose up to ${modifier.options.length}`
  }

  const isModifierOptionsDisabled = (option) => {
    if (modifier.max > 0) {
      if (values.indexOf(option._id.toString()) === -1 && values.length >= modifier.max)
        return true;
    }

    return false;
  }

  return (
    <Grid container key={modifier._id} className={classes.holder} direction="column" display="flex" spacing={1}>
      <Grid item>
        <Typography variant="h6">{modifier.name}</Typography>
        <Typography variant="subtitle2">{subTitle}</Typography>
      </Grid>
      <Grid item>
        <Typography variant="caption">{modifier.description}</Typography>
      </Grid>
      {modifier.options.map(option => <Grid item key={option._id.toString()} >
        <FormControlLabel
          key={option._id}
          style={{ width: "100%" }}
          control={<Checkbox id={option._id.toString()} disabled={isModifierOptionsDisabled(option)} checked={values.indexOf(option._id.toString()) !== -1} onClick={onClick} name={option.name} />}

          label={(
            <Grid container justifyContent="space-between">
              <Grid item style={{ width: "100%", flex: 1 }}>
                <Typography>
                  {option.name}
                </Typography>
              </Grid>
              {option.price > 0 && <Grid item>
                <Typography className={classes.price} >
                  +{Intl.NumberFormat('en-US', { style: "currency", currency: "USD", minimumFractionDigits: 2, }).format(option.price / 100)}
                </Typography>
              </Grid>}
            </Grid>
          )}
        />
      </Grid>)}
    </Grid>
  );
}

export default MultipleModifiers;
