import { Box, Typography, withStyles } from '@material-ui/core';
import React, { Component } from 'react';

class ErrorMessage extends Component {
  state = {}
  render() {

    const { classes, holderStyle, textStyle } = this.props;
    return (
      <Box className={classes.errorHolder} style={{ ...holderStyle }}>
        <Typography align="center" className={classes.errorText} style={{ ...textStyle }}>We are not accepting any orders at this time. We apologize for the inconvenience.</Typography>
      </Box>
    );
  }
}

export default withStyles(theme => ({
  errorHolder: {
    justityContent: "center",
    alignItems: "center",
    padding: 16,
    backgroundColor: theme.palette.error.main,
    position: "sticky",
    top: 56,
    [theme.breakpoints.up("sm")]: {
      top: 64

    },
    zIndex: 10
  },
  errorText: {
    color: "#fff",

  }
}))(ErrorMessage);